/**
 * @Deprecated Use getDonations service instead (DonorService.js)
 */
export function getDonationsQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/donations.json?account_id=${account_identifier}&offset=${offset}`
  );

  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getDonorsQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/people.json?account_id=${account_identifier}&offset=${offset}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getCampaignsQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/campaigns.json?account_id=${account_identifier}&offset=${offset}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getFundraisersQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/fundraisers.json?account_id=${account_identifier}&offset=${offset}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getSubscriptionsQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/subscriptions.json?account_id=${account_identifier}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getMeQuery() {
  const url = new URL(`${process.env.DONATELY_API_V2_URL}/people/me.json`);
  return url;
}

export function getMyDonationsQuery(offset) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/donations/mine.json?offset=${offset}`
  );
  return url;
}

export function getMyOrderedDonations(offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/donations/mine.json?offset=${offset}&${query}`
  );
  return url;
}

export function getMySubscriptionsQuery(offset) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/subscriptions/mine.json?offset=${offset}&status=all&order_by=recurring_start_day&order=desc`
  );
  return url;
}

export function getMyOrderedSubscriptions(offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/subscriptions/mine.json?offset=${offset}&${query}`
  );
  return url;
}

export function getMyFundraisersQuery(offset) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/fundraisers/mine.json?offset=${offset}`
  );
  return url;
}

export function getWebhooksQuery(account_identifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/webhooks.json?account_id=${account_identifier}&offset=${offset}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getWebhooksAttemptsQuery(accountIdentifier, offset, query) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/webhook_retrys.json?account_id=${accountIdentifier}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}

export function getFormsQuery(account_identifier, offset, query, limit) {
  const url = new URL(
    `${process.env.DONATELY_API_V2_URL}/forms.json?account_id=${account_identifier}&limit=${limit}`
  );
  Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
  return url;
}
