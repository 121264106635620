import { doRequest } from './ApiBase';
import { rebuildApiHeaders } from '../app/library/functions';

export const getFundraisers = async data => {
  const { headers, urlQuery, params } = data;
  return doRequest({
    url: `/fundraisers.json${urlQuery || ''}`,
    method: 'GET',
    headers: rebuildApiHeaders(headers, '2021-11-15'),
    params,
  });
};
