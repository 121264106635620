import React from 'react';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import Tooltip from '../../Tooltip';

const ToggleComponent = ({
  className,
  sliderClassName,
  disabled,
  field,
  form,
  groupFilterName,
  label,
  labelContainerCustomClass,
  link,
  linkText,
  linkTarget = '_blank',
  onChange,
  tooltip,
  tooltipCustomClass,
  tooltipSize,
  tooltipViewBoxSize,
  ...rest
}) => {
  const handleOnChange = ev => {
    if (groupFilterName) {
      onChange(ev, groupFilterName, form);
      field.onChange(ev);
    } else {
      onChange(ev);
    }
  };
  return (
    <div className={className}>
      <div className="flex m-2">
        <label className="switch self-center">
          <input
            type="checkbox"
            {...field}
            {...rest}
            onChange={onChange ? handleOnChange : field.onChange}
            disabled={disabled}
            checked={field.value}
            value={field.value || ''}
          />
          <span className={sliderClassName || `slider round`} />
        </label>
        <div className={labelContainerCustomClass}>
          <label className="pl-4 text-sm text-grey-dark font-light inline-block w-2/4 self-center">
            {label}
            {link && (
              <a
                className="no-underline text-grey-dark hover:text-green"
                href={link}
                target={linkTarget}
              >
                {linkText}
              </a>
            )}
          </label>
          {tooltip && typeof tooltip === 'function' && <>{tooltip()}</>}
          {tooltip && typeof tooltip === 'string' && (
            <Tooltip
              text={tooltip}
              size={tooltipSize}
              viewBoxSize={tooltipViewBoxSize}
              customClass={tooltipCustomClass}
            />
          )}
        </div>
      </div>
      <ErrorMessage
        component="div"
        className="text-center text-sm text-red mb-2 mt-2"
        name={field.name}
      />
    </div>
  );
};

ToggleComponent.defaultProps = {
  disabled: false,
  labelContainerCustomClass: 'flex',
  tooltip: false,
  tooltipCustomClass: '',
  tooltipSize: 20,
  tooltipViewBoxSize: 24,
  type: 'checkbox',
};

ToggleComponent.propTypes = {
  className: PropTypes.string,
  sliderClassName: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  groupFilterName: PropTypes.string,
  label: PropTypes.string,
  labelContainerCustomClass: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.any,
  tooltipCustomClass: PropTypes.string,
  tooltipSize: PropTypes.number,
  tooltipViewBoxSize: PropTypes.number,
  type: PropTypes.string,
};

const ToggleFormik = props => <Field component={ToggleComponent} {...props} />;

export default ToggleFormik;
