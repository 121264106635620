import React from 'react';
import uuidv4 from 'uuid/v4';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import {
  rebuildApiHeaders,
  isQueryChanged,
  getInnerProp,
} from '../../library/functions';
import { CACHE_VALID_TIME } from '../../library/constants';

export const REQUEST_DONORS = 'REQUEST_DONORS';

export function requestDonors(accountIdentifier, headers) {
  return {
    type: REQUEST_DONORS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const SET_DONOR_QUERY = 'SET_DONOR_QUERY';

export function setDonorQuery(query) {
  return {
    type: SET_DONOR_QUERY,
    query,
  };
}

export const RECEIVE_DONORS = 'RECEIVE_DONORS';

export function receiveDonors(accountIdentifier, json) {
  return {
    type: RECEIVE_DONORS,
    account_identifier: accountIdentifier,
    donors: json.data,
    summary: json.summary,
    orderBy: json.params,
    receivedAt: Date.now(),
    offset: json.params.offset,
    invalidTimestamp: Date.now().valueOf() + CACHE_VALID_TIME,
  };
}

export const RECEIVE_EMPTY_DONORS = 'RECEIVE_EMPTY_DONORS';

export function receiveEmptyDonors(accountIdentifier, currentPage) {
  return {
    type: RECEIVE_EMPTY_DONORS,
    account_identifier: accountIdentifier,
    currentPage,
  };
}

export const EDIT_DONOR = 'EDIT_DONOR';

export function editDonor(donorId, headers) {
  return {
    type: EDIT_DONOR,
    donorId,
    headers,
  };
}

export const SELECTED_DONOR = 'SELECTED_DONOR';

export function selectedDonor(donor) {
  return {
    type: SELECTED_DONOR,
    donor,
  };
}

export const CLEAR_SELECTED_DONOR = 'CLEAR_SELECTED_DONOR';

export function clearSelectedDonor() {
  return {
    type: CLEAR_SELECTED_DONOR,
  };
}

export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';

export function receiveExport(message) {
  return {
    type: RECEIVE_EXPORT,
    message,
  };
}

export const SET_DONORS_CACHED_QUERY = 'SET_DONORS_CACHED_QUERY';

export function setDonorsCachedQuery(accountIdentifier, cachedQuery) {
  return {
    type: SET_DONORS_CACHED_QUERY,
    account_identifier: accountIdentifier,
    cachedQuery,
  };
}

export const CLEAR_DONORS = 'CLEAR_DONORS';

export function clearDonors(accountIdentifier) {
  return {
    type: CLEAR_DONORS,
    account_identifier: accountIdentifier,
  };
}

export const UPDATE_CACHED_DONOR = 'UPDATE_CACHED_DONOR';

export function updateCachedDonor(accountIdentifier, donor) {
  return {
    type: UPDATE_CACHED_DONOR,
    account_identifier: accountIdentifier,
    donor,
  };
}

/**
 * Export Donors
 * Note: This function is out of dispatch behaivor
 */
export const exportDonors = (
  accountIdentifier,
  headers,
  offset = '0',
  query = ''
) => {
  const url = Queries.getDonorsQuery(accountIdentifier, offset, query);

  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  });
};

const attemptDonorFetch = (
  url,
  headers,
  accountIdentifier,
  query,
  dispatch,
  attempts
) =>
  fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  }).then(function(response) {
    if (response.status !== 200) {
      const currentAttempts = attempts + 1;
      if (currentAttempts < 3) {
        // retry three times
        return attemptDonorFetch(
          url,
          headers,
          accountIdentifier,
          query,
          dispatch,
          currentAttempts
        );
      }
    } else {
      return response.json().then(json => {
        dispatch(receiveDonors(accountIdentifier, json));
        dispatch(setDonorQuery(query));
        // Updates the cached query for this account.
        dispatch(setDonorsCachedQuery(accountIdentifier, query));
      });
    }
  });

export function fetchDonors(
  accountIdentifier,
  headers,
  offset = '0',
  page,
  query = ''
) {
  const url = Queries.getDonorsQuery(accountIdentifier, offset, query);

  if (query.hasOwnProperty('export_columns')) {
    return dispatch =>
      fetch(url, {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }).then(function(response) {
        if (response.status === 200) {
          dispatch(receiveExport('success'));
        } else if (response.status === 404 || response.status === 500) {
          dispatch(receiveExport('error'));
        }
      });
  }

  return (dispatch, getState) => {
    // Clear if query changed against the cachedQuery
    let state = getState().donorReducer;
    const cachedQuery = getInnerProp(
      state,
      `donorsByAccount.${accountIdentifier}.cachedQuery`
    );
    if (state && cachedQuery && isQueryChanged(cachedQuery, query)) {
      dispatch(clearDonors(accountIdentifier));
    }

    dispatch(requestDonors(accountIdentifier, headers));

    // Only request more data when offset in state is less than requested.
    state = getState().donorReducer;
    const prevOffset = getInnerProp(
      state,
      `donorsByAccount.${accountIdentifier}.offset`
    );
    if (state && prevOffset >= 0) {
      // Only request new page when needed
      // Map pages to int
      const storePages = getInnerProp(
        state,
        `donorsByAccount.${accountIdentifier}.pages`
      );
      const pages = Object.keys(storePages).map(p => parseInt(p));
      const currentPage = parseInt(page) - 1;

      // If page is found (note page-1) and is valid by timestamp, then retrieve the data from store
      const foundPage = pages.find(p => p === currentPage);
      const invalidateAfter = getInnerProp(
        storePages,
        `${foundPage}.invalidateAfter`
      );
      const now = new Date().valueOf();
      if (foundPage >= 0 && now <= invalidateAfter) {
        return dispatch(receiveEmptyDonors(accountIdentifier, currentPage));
      }
    }

    return attemptDonorFetch(
      url,
      headers,
      accountIdentifier,
      query,
      dispatch,
      0
    );
  };
}

export function fetchDonor(accountIdentifier, headers, donorId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/people/${donorId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response.json().then(json => dispatch(selectedDonor(json.data)));
      }
    });
}

export function updateDonor(accountIdentifier, headers, donorId, updateValues) {
  return dispatch => {
    dispatch(editDonor(donorId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/people/${donorId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, uuidv4())));
      }

      return response
        .json()
        .then(json => dispatch(selectedDonor(json.data)))
        .then(dispatch(receiveFail(null)));
    });
  };
}

export function sendPasswordReset(email) {
  return dispatch =>
    fetch(`https://${process.env.AUTH0_DOMAIN}/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.AUTH0_CLIENT_ID,
        password: null,
        email,
        connection: process.env.AUTH0_CONNECTION,
      }),
    }).then(function(response) {
      if (response.status !== 200) {
        dispatch(receiveFail('Unable to send password reset.'));
      } else {
        dispatch(receiveFail('Password reset sent.'));
      }
    });
}

export function shouldFetchDonors(state, accountIdentifier) {
  const donors = state.donorReducer.donorsByAccount[accountIdentifier];
  if (!donors) {
    return true;
  }
  if (donors.isFetching || donors.items) {
    return false;
  }
}

export function fetchDonorsIfNeeded(accountIdentifier, headers) {
  return (dispatch, getState) => {
    if (shouldFetchDonors(getState(), accountIdentifier)) {
      return dispatch(fetchDonors(accountIdentifier, headers));
    }
  };
}

export function sendLastYearDonationsSummary(params) {
  return () => {
    const { accountId, headers, data } = params;
    fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountId}/send_tax_statement`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      }
    );
  };
}

export function checkLastYearDonationsSummaryStatus() {
  return async (dispatch, getState) => {
    const currentState = getState();
    const accountId = currentState.accountReducer.selectedAccount.id;
    const headers = currentState.accountReducer.storeUser;
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountId}/send_tax_statement?check_tax_statement_status=true`,
      {
        method: 'POST',
        headers: rebuildApiHeaders(headers),
      }
    );
    if (response.status === 200) {
      const responseJson = await response.json();
      return responseJson.data;
    }
    console.error('Donation summary response');
  };
}

export function sendDonorReport(params) {
  const { accountId, headers, data } = params;
  return fetch(
    `${process.env.DONATELY_API_V2_URL}/accounts/${accountId}/send_donor_report`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    }
  );
}
