import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  selectedAccount as selectedAccountAction,
  fetchAccount,
  fetchHubspotToken,
  isDonor as isDonorAction,
} from './user-accounts.actions';
import TopNav from './components/top-nav.component';
import { LoadingAccounts } from '../../library/components/Loading';
import { useAppContext } from '../../AppContext';
import { ACCOUNT_CHANGE_EVENT } from '../../EventManager';
import { isDonatelyTeamMember, consoleLog } from '../../library/functions';

const UserAccountsContainer = ({ dispatch, auth }) => {
  const appContext = useAppContext();
  const [supportChatLaunched, setSupportChatLaunched] = useState(false);
  const [hubspotIdentity, setHubspotIdentity] = useState(false);
  const { storeUser, accountsByUser, selectedAccount, isDonor } = useSelector(
    state => state.accountReducer
  );
  const { userSettings: currentUserSettings } = useSelector(
    state => state.myProfileReducer
  );
  const { isFetching, items: accounts } = accountsByUser[storeUser] || {
    isFetching: true,
    items: [],
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous = usePrevious({ selectedAccount });

  useEffect(() => {
    if (selectedAccount && !selectedAccount.hasOwnProperty('billing')) {
      auth.logout('UserAccountsContainer');
    }
  }, []);

  useEffect(() => {
    if (isDonor === 'true') {
      goToProfile();
    } else if (isDonor === 'false') {
      goToAccountDashboard();
    }
  }, [isDonor]);

  useEffect(() => {
    if (previous && previous?.selectedAccount?.id !== selectedAccount.id) {
      const prevSelectedAccount = previous.selectedAccount;

      appContext.eventManager.emit(ACCOUNT_CHANGE_EVENT, {
        current: selectedAccount,
        previous: prevSelectedAccount,
      });
    }
  }, [selectedAccount]);

  useEffect(() => {
    const { email, token } = localStorage.getItem('hubspotTokenObject')
      ? JSON.parse(localStorage.getItem('hubspotTokenObject'))
      : { email: null, token: null };

    const matchingToken = email && currentUserSettings.email === email && token;

    if (!matchingToken && currentUserSettings.email) {
      dispatch(fetchHubspotToken(currentUserSettings.email, storeUser));
    }

    if (matchingToken && !supportChatLaunched) {
      // console.log('setSupportChatLaunched', { email }, { token });
      window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
      };
      if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.load();
      }
      setSupportChatLaunched(true);
    }

    if (window._hsq && currentUserSettings.id && !hubspotIdentity) {
      const identity = {
        id: currentUserSettings.id,
        email: currentUserSettings.email,
        firstname: currentUserSettings.first_name,
        lastname: currentUserSettings.last_name,
      };
      window._hsq.push(['identify', identity]);
      setHubspotIdentity(true);
      // consoleLog(
      //   `HubspotAnalytics | identify`,
      //   { identity },
      //   { codeArea: 'routes' }
      // );
    }
  }, [
    currentUserSettings,
    selectedAccount,
    storeUser,
    supportChatLaunched,
    setSupportChatLaunched,
  ]);

  /* eslint-disable no-undef */
  useEffect(() => {
    if (typeof heap !== 'undefined' && currentUserSettings.email) {
      heap.identify(currentUserSettings.email);
      if (selectedAccount) {
        heap.addUserProperties({
          subdomain: selectedAccount.subdomain,
          subscription_plan: selectedAccount.hasOwnProperty('billing')
            ? selectedAccount.billing.subscription_plan
            : selectedAccount.subscription_plan,
        });
      }
    }
  }, [currentUserSettings, heap, selectedAccount]);
  /* eslint-enable no-undef */

  const handleChange = account => {
    dispatch(selectedAccountAction(account));
  };

  const goToProfile = () => {
    localStorage.setItem('isDonor', 'true');
    dispatch(isDonorAction('true'));
    document
      .getElementById('changing-header')
      .classList.add('donor-background');
    document
      .getElementById('changing-header')
      .classList.remove('topnav-blue-background');
  };

  const goToAccountDashboard = () => {
    localStorage.setItem('isDonor', 'false');
    dispatch(isDonorAction('false'));
    document
      .getElementById('changing-header')
      .classList.add('topnav-blue-background');
    document
      .getElementById('changing-header')
      .classList.remove('donor-background');
  };

  const switchAccount = subdomain => {
    dispatch(fetchAccount(storeUser, subdomain));
  };

  return (
    <div>
      <div id="changing-header" className="topnav-blue-background">
        <TopNav
          selectedAccount={selectedAccount}
          accounts={accounts}
          selected={handleChange}
          currentUserSettings={currentUserSettings}
          auth={auth}
          isDonor={isDonor}
          goToProfile={goToProfile}
          goToAccountDashboard={goToAccountDashboard}
          switchAccount={switchAccount}
        />
      </div>
    </div>
  );
};

UserAccountsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(UserAccountsContainer);
