export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export function showMessage(
  text,
  messageType = 'success',
  onAgree = null,
  noCancel = true,
  modalExport = false
) {
  let newNoCancel = noCancel;
  if (messageType === 'error') {
    newNoCancel = true;
  }

  if (messageType === 'confirmation') {
    newNoCancel = false;
  }

  return {
    type: SHOW_MESSAGE,
    text,
    messageType,
    noCancel: newNoCancel,
    onAgree,
    export: modalExport,
  };
}

export const HIDE_MODAL = 'HIDE_MODAL';

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';

export function showUpgradeModal(plan, isYearly, onUpgradeSuccess = null) {
  return {
    type: SHOW_UPGRADE_MODAL,
    plan,
    isYearly,
    onUpgradeSuccess,
  };
}

export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';

export function hideUpgradeModal() {
  return {
    type: HIDE_UPGRADE_MODAL,
  };
}
