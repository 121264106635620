import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EarmarkCampaign = () => (
  <div className="fa-layers fa-fw block mx-auto mb-6">
    <FontAwesomeIcon
      icon={['fal', 'file-invoice-dollar']}
      size="2x"
      transform="grow-2"
    />
  </div>
);

export default EarmarkCampaign;
