import { combineReducers } from 'redux';
import {
  REQUEST_TEXT2GIVE,
  RECEIVE_TEXT2GIVE,
} from './text-engagement.actions';

function text2give(
  state = {
    isFetching: false,
    items: null,
  },
  action
) {
  switch (action.type) {
    case REQUEST_TEXT2GIVE:
      return { ...state, isFetching: true };
    case RECEIVE_TEXT2GIVE:
      return { ...state, isFetching: false, items: action.text2give };
    default:
      return state;
  }
}

function text2giveByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_TEXT2GIVE:
    case REQUEST_TEXT2GIVE:
      return {
        ...state,
        [action.accountIdentifier]: text2give(
          state[action.accountIdentifier],
          action
        ),
      };
    default:
      return state;
  }
}

const textEngagementReducer = combineReducers({
  text2giveByAccount,
});

export default textEngagementReducer;
