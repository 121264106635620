import { rebuildApiHeaders } from '../../../library/functions';

export const UPSERT_HTML_CONTENTS = `UPSERT_HTML_CONTENTS`;

export function upsertHtmlContents(user, values) {
  return async () => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/html_contents`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
        body: JSON.stringify(values),
      }
    );

    if (response.status !== 200) {
      console.error('error upserting HTML contents');
      return false;
    }

    return true;
  };
}

export const GET_HTML_CONTENTS_SUCCESS = 'GET_HTML_CONTENTS_SUCCESS';
export const GET_HTML_CONTENTS_FAILURE = 'GET_HTML_CONTENTS_FAILURE';

export function getHtmlContents(formId, headers, accountId) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/html_contents.json?form_id=${formId}&account_id=${accountId}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      dispatch({
        type: GET_HTML_CONTENTS_FAILURE,
      });
    } else {
      const responseJson = await response.json();
      dispatch({
        type: GET_HTML_CONTENTS_SUCCESS,
        htmlContents: responseJson.data,
      });
    }
  };
}
