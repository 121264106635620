import React, { Component, Fragment } from 'react';
import { SuccessSvg } from './Icons';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.renderExportOptions = this.renderExportOptions.bind(this);
    this.renderRefundReason = this.renderRefundReason.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);

    this.state = {
      selectedReason: 'requested_by_customer',
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  closeModal() {
    this.props.closeFunction();
  }

  acceptTerms() {
    if (this.props.export) {
      const radio = document.querySelector('input[name="columns"]:checked')
        ? document.querySelector('input[name="columns"]:checked').value
        : 'all';
      this.props.agreeFunction(radio);
    } else if (this.props.showRefundReason) {
      this.props.agreeFunction(this.state.selectedReason);
      this.closeModal();
    } else {
      this.props.agreeFunction();
      this.closeModal();
    }
  }

  handleReasonChange(e) {
    this.setState({
      selectedReason: e.target.value,
    });
  }

  handleOutsideClick(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      document.removeEventListener('click', this.handleOutsideClick, false);
      this.closeModal();
    }
  }

  renderExportOptions() {
    return (
      <div className="w-48 mx-auto mt-2">
        <div className="mb-1">
          <div>
            <input type="radio" value="all" name="columns" />
            <label className="pl-2">Export all columns</label>
          </div>
          <div>
            <input type="radio" value="visible" name="columns" />
            <label className="pl-2">Export visible columns</label>
          </div>
        </div>
      </div>
    );
  }

  renderRefundReason() {
    return (
      <div>
        <div className="text-center m-3 mx-2">
          To continue, please select a reason below.
        </div>
        <div className="w-48 mx-auto mt-2 mb-8">
          <div>
            <input
              type="radio"
              name="refund_reason"
              value="requested_by_customer"
              checked={this.state.selectedReason === 'requested_by_customer'}
              onChange={this.handleReasonChange}
            />
            <label className="pl-2">Requested By Customer</label>
          </div>
          <div>
            <input
              type="radio"
              name="refund_reason"
              value="duplicate"
              checked={this.state.selectedReason === 'duplicate'}
              onChange={this.handleReasonChange}
            />
            <label className="pl-2">Duplicate</label>
          </div>
          <div className="mb-1">
            <input
              type="radio"
              name="refund_reason"
              value="fraudulent"
              checked={this.state.selectedReason === 'fraudulent'}
              onChange={this.handleReasonChange}
            />
            <label className="pl-2">Fraudulent</label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const modalText = this.props.modalText || 'Are you sure?';
    const modalOkText = this.props.modalOkText || 'OK';
    const modalCancelText = this.props.modalCancelText || 'Cancel';

    return (
      <div className="flex fixed modal-wrapper z-40">
        <div
          ref={node => {
            this.node = node;
          }}
          className=" flex-col bg-white content-center rounded-lg shadow-lg p-2 m-auto sm:w-3/5 w-3/4"
        >
          <span className="flex justify-end">
            <svg
              className="fill-current cursor-pointer text-black"
              onClick={() => this.closeModal()}
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
            >
              <title>Close</title>
              <path
                d="M16.2,14.8c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L12,13.4l-2.8,2.8c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l2.8-2.8
              L7.8,9.2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.8,2.8l2.8-2.8c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12L16.2,14.8z"
              />
            </svg>
          </span>
          <div className="p-10 text-center">
            {this.props.noCancel && modalText.includes('successfully') && (
              <div>
                <SuccessSvg />
                <div className="text-center m-3 mx-2 text-green text-2xl">
                  Action Completed
                </div>
              </div>
            )}
            <div className="text-center m-3 mx-2 text-black text-base">
              {modalText}
            </div>

            {this.props.export && this.renderExportOptions()}

            {this.props.showRefundReason && this.renderRefundReason()}

            {!this.props.noCancel && (
              <div className="flex justify-center mt-10">
                <button
                  className="dntly-btn-danger pin-r pin-b mb-4 mr-4 mt-2 sm:w-1/4 w-1/2"
                  onClick={() => this.closeModal()}
                >
                  {modalCancelText}
                </button>

                <button
                  className="dntly-btn-primary pin-l pin-b mb-4 ml-4 mt-2 sm:w-1/4 w-1/2"
                  onClick={() => this.acceptTerms()}
                >
                  {modalOkText}
                </button>
              </div>
            )}

            {this.props.noCancel && !modalText.includes('successfully') && (
              <div className="text-center m-3">
                <button
                  className="dntly-btn-primary"
                  onClick={() => this.acceptTerms()}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
