import React from 'react';

const MonitorSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="fill-current h-6 w-6 "
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      className="heroicon-ui"
      d="M13 17h-2v2h2v-2zm2 0v2h2a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h2v-2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5zM4 5v10h16V5H4z"
    />
  </svg>
);

export default MonitorSvg;
