import { combineReducers } from 'redux';
import {
  REQUEST_SETTINGS,
  RECEIVE_SETTINGS,
  INVALIDATE_SETTING,
  REQUEST_ADMINS,
  RECEIVE_ADMINS,
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS,
  RECEIVE_STRIPE_KEYS,
  RECEIVE_ACCOUNT_CC,
  RECEIVE_MERCHANT_STATUS,
} from './account-settings.actions';

function settings(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: null,
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_SETTING:
      return { ...state, didInvalidate: true };
    case REQUEST_SETTINGS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_SETTINGS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.settings,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

function notifications(
  state = {
    isFetching: false,
    items: null,
  },
  action
) {
  switch (action.type) {
    case REQUEST_NOTIFICATIONS:
      return { ...state, isFetching: true };
    case RECEIVE_NOTIFICATIONS:
      return { ...state, isFetching: false, items: action.notifications };
    default:
      return state;
  }
}

function stripeKeys(
  state = {
    publishableKey: null,
    secretKey: null,
  },
  action
) {
  switch (action.type) {
    case RECEIVE_STRIPE_KEYS:
      return {
        ...state,
        publishableKey: action.publishableKey,
        secretKey: action.secretKey,
      };
    default:
      return state;
  }
}

function settingsByAccount(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_SETTING:
    case RECEIVE_SETTINGS:
    case REQUEST_SETTINGS:
      return {
        ...state,
        [action.account_identifier]: settings(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

function notificationsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATIONS:
    case REQUEST_NOTIFICATIONS:
      return {
        ...state,
        [action.account_identifier]: notifications(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

function admins(
  state = {
    isFetching: false,
    items: null,
  },
  action
) {
  switch (action.type) {
    case REQUEST_ADMINS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_ADMINS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.admins,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

function adminsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ADMINS:
    case REQUEST_ADMINS:
      return {
        ...state,
        [action.account_identifier]: admins(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

export function accountCC(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT_CC:
      return action.accountCC;
    default:
      return state;
  }
}

export function merchantStatus(state = {}, action) {
  switch (action.type) {
    case RECEIVE_MERCHANT_STATUS:
      return { ...state, ...action.paypal_merchant_settings };
    default:
      return state;
  }
}

const settingsReducer = combineReducers({
  settingsByAccount,
  stripeKeys,
  notificationsByAccount,
  adminsByAccount,
  accountCC,
  merchantStatus,
});

export default settingsReducer;
