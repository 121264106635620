import { combineReducers } from 'redux';
import {
  REQUEST_FUNDRAISERS,
  RECEIVE_FUNDRAISERS,
  INVALIDATE_FUNDRAISER,
  SELECTED_FUNDRAISER,
  RECEIVE_EXPORT,
  SET_FUNDRAISER_QUERY,
  RECEIVE_FUNDRAISERS_RECURSIVELY,
  INIT_FUNDRAISERS_FOR_ACCOUNT,
  UPDATE_RECURSIVE_FUNDRAISER_PROGRESS,
} from './fundraisers.actions';

export function selectedFundraiser(state = {}, action) {
  switch (action.type) {
    case SELECTED_FUNDRAISER:
      return action.fundraiser;
    default:
      return state;
  }
}

export function receiveFundraiserQuery(state = {}, action) {
  switch (action.type) {
    case SET_FUNDRAISER_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function exportMessage(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPORT:
      return action.message;
    default:
      return state;
  }
}

function fundraisers(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    summary: {},
    orderBy: '',
    message: null,
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_FUNDRAISER:
      return { ...state, didInvalidate: true };
    case REQUEST_FUNDRAISERS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_FUNDRAISERS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.fundraisers,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_FUNDRAISERS_RECURSIVELY:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.fundraisers,
        message: null,
      };
    case UPDATE_RECURSIVE_FUNDRAISER_PROGRESS:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}

export function fundraisersByAccount(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_FUNDRAISER:
    case RECEIVE_FUNDRAISERS:
    case REQUEST_FUNDRAISERS:
    case RECEIVE_FUNDRAISERS_RECURSIVELY:
    case UPDATE_RECURSIVE_FUNDRAISER_PROGRESS:
      return {
        ...state,
        [action.account_identifier]: fundraisers(
          state[action.account_identifier],
          action
        ),
      };
    case INIT_FUNDRAISERS_FOR_ACCOUNT:
      return {
        ...state,
        [action.account_identifier]: {
          isFetching: false,
          didInvalidate: false,
          items: [],
          summary: {},
          orderBy: '',
        },
      };
    default:
      return state;
  }
}

const fundraiserReducer = combineReducers({
  fundraisersByAccount,
  selectedFundraiser,
  receiveFundraiserQuery,
  exportMessage,
});

export default fundraiserReducer;
