// declare the event emitter var here to be useed everywhere in your app
import { EventEmitter } from 'fbemitter';

// mr Emitter is your friendly next door emitter
const EventManager = new EventEmitter();

export default EventManager;

// List of events
export const ACCOUNT_CHANGE_EVENT = 'account:change';

// Campaign Events
export const CAMPAIGN_CREATE_FORM_EVENT = 'campaign:createForm';
