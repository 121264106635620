import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const P2PCampaign = () => (
  <div className="fa-layers fa-fw block mx-auto mb-6">
    <FontAwesomeIcon icon={['fal', 'browser']} size="2x" transform="grow-2" />
    <FontAwesomeIcon
      icon={['far', 'user-friends']}
      transform="shrink-1"
      style={{ background: '#f1f5f8', transform: 'translate(20px, 13px)' }}
    />
  </div>
);

export default P2PCampaign;
