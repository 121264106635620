import React from 'react';
import * as Queries from '../../library/query.module';
import { receiveFail } from '../donations/donations.actions';
import { rebuildApiHeaders } from '../../library/functions';
import { selectedAccount } from '../user-accounts/user-accounts.actions';
import { showMessage } from '../modal/modal.actions';

export const REQUEST_WEBHOOK_ATTEMPTS = 'REQUEST_WEBHOOKS';

export function requestWebhookAttempts(accountIdentifier, headers, webhookID) {
  return {
    type: REQUEST_WEBHOOK_ATTEMPTS,
    account_identifier: accountIdentifier,
    headers,
    webhookID,
  };
}

export const RECEIVE_WEBHOOK_ATTEMPTS = 'RECEIVE_WEBHOOK_ATTEMPTS';

export function receiveWebhookAttempts(accountIdentifier, json, webhookID) {
  return {
    type: RECEIVE_WEBHOOK_ATTEMPTS,
    account_identifier: accountIdentifier,
    attempts: json.data,
    summary: json.summary,
    webhookID,
  };
}

export const REQUEST_WEBHOOKS = 'REQUEST_WEBHOOKS';

export function requestWebhooks(accountIdentifier, headers) {
  return {
    type: REQUEST_WEBHOOKS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const RECEIVE_WEBHOOKS = 'RECEIVE_WEBHOOKS';

export function receiveWebhooks(accountIdentifier, json) {
  return {
    type: RECEIVE_WEBHOOKS,
    account_identifier: accountIdentifier,
    webhooks: json.data,
    summary: json.summary,
    orderBy: json.params,
  };
}

export const SET_WEBHOOK_QUERY = 'SET_WEBHOOK_QUERY';

export function setWebhookQuery(query) {
  return {
    type: SET_WEBHOOK_QUERY,
    query,
  };
}

export const SET_WEBHOOK_ATTEMPTS_QUERY = 'SET_WEBHOOK_ATTEMPTS_QUERY';

export function setWebhookAttemptsQuery(query) {
  return {
    type: SET_WEBHOOK_ATTEMPTS_QUERY,
    query,
  };
}

export const REQUEST_WEBHOOK = 'REQUEST_WEBHOOK';

export function requestWebhook(accountIdentifier, headers, webhookId) {
  return {
    type: REQUEST_WEBHOOK,
    account_identifier: accountIdentifier,
    headers,
    webhookId,
  };
}

export const SELECTED_WEBHOOK = 'SELECTED_WEBHOOK';

export function selectedWebhook(webhook) {
  return {
    type: SELECTED_WEBHOOK,
    webhook,
  };
}

export const EDIT_WEBHOOK = 'EDIT_WEBHOOK';

export function editWebhook(hookId, headers) {
  return {
    type: EDIT_WEBHOOK,
    hookId,
    headers,
  };
}

export function fetchWebhooks(
  accountIdentifier,
  headers,
  offset = 0,
  query = ''
) {
  const url = Queries.getWebhooksQuery(accountIdentifier, offset, query);
  return dispatch => {
    dispatch(requestWebhooks(accountIdentifier, headers));
    return fetch(url, {
      type: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveWebhooks(accountIdentifier, json)))
          .then(dispatch(setWebhookQuery(query)));
      }
    });
  };
}

export function fetchWebhook(accountIdentifier, headers, webhookId) {
  return dispatch => {
    dispatch(requestWebhook(accountIdentifier, headers, webhookId));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/webhooks/${webhookId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(selectedWebhook(json.data));
      });
    });
  };
}

export function deleteWebhook(accountIdentifier, headers, webhookId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/webhooks/${webhookId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail('Webhook sucessfully deleted.', Date.now()));
        dispatch(fetchWebhooks(accountIdentifier, headers));
      });
    });
}

export function createWebhook(
  accountIdentifier,
  headers,
  hookType,
  url,
  title
) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/webhooks.json?account_id=${accountIdentifier}&hook=${hookType}&post_url=${url}&title=${title}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail('Webhook sucessfully created.', Date.now()));
        dispatch(fetchWebhooks(accountIdentifier, headers));
      });
    });
}

export function updateWebhook(
  accountIdentifier,
  headers,
  hookId,
  updateValues
) {
  return dispatch => {
    dispatch(editWebhook(hookId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/webhooks/${hookId}.json?account_id=${accountIdentifier}&disabled=${updateValues.disabled}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json => dispatch(selectedWebhook(json.data)))
        .then(() => dispatch(fetchWebhooks(accountIdentifier, headers)))
        .then(() =>
          dispatch(receiveFail('Webhook sucessfully updated.', Date.now()))
        );
    });
  };
}

export function resendWebhookAttempt(
  accountIdentifier,
  selectedAttempt,
  headers,
  selectedWebhookArg,
  offset = 0
) {
  // console.log('resendWebhook', accountIdentifier, selectedAttempt, offset, selectedWebhookArg)
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/webhook_retrys/${selectedAttempt.id}/rerun.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail('Payload queued for resend.', Date.now()));
        dispatch(
          fetchWebhookAttempts(
            accountIdentifier,
            headers,
            selectedWebhookArg.id,
            offset
          )
        );
      });
    });
}

export function fetchWebhookAttempts(
  accountIdentifier,
  headers,
  webhookID,
  offset = 0
) {
  //
  // le.log('fetchWebhookAttempts', accountIdentifier, webhookID, offset)
  return dispatch => {
    dispatch(requestWebhookAttempts(accountIdentifier, headers, webhookID));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/webhook_retrys.json?account_id=${accountIdentifier}&offset=${offset}&webhook_id=${webhookID}`,
      {
        type: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json =>
            dispatch(receiveWebhookAttempts(accountIdentifier, json, webhookID))
          );
      }
    });
  };
}

export function fetchWebhookAttemptsV2(
  accountIdentifier,
  headers,
  offset = 0,
  query = ''
) {
  const url = Queries.getWebhooksAttemptsQuery(
    accountIdentifier,
    offset,
    query
  );

  return dispatch => {
    dispatch(
      requestWebhookAttempts(accountIdentifier, headers, query.webhook_id)
    );
    return fetch(url, {
      type: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json =>
            dispatch(
              receiveWebhookAttempts(accountIdentifier, json, query.webhook_id)
            )
          );
      }
    });
  };
}

export function pushHubspotDataToServer(accountIdentifier, headers, code) {
  return async (dispatch, getState) => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/hubspot/connect`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
        body: JSON.stringify({
          authorization_code: code,
          selected_account: accountIdentifier,
        }),
      }
    );

    const currentState = getState();
    const currentSelectedAccount = currentState.accountReducer.selectedAccount;
    if (response.status === 200) {
      dispatch({
        type: 'HUBSPOT_CONNECTING_DATA_SUCCESS',
      });

      if (!currentSelectedAccount.integration_accounts.includes('hubspot')) {
        currentSelectedAccount.integration_accounts.push('hubspot');
        dispatch(selectedAccount(currentSelectedAccount));
      }

      return true;
    }
    dispatch({
      type: 'HUBSPOT_CONNECTING_DATA_ERROR',
    });
    return false;
  };
}

export const GET_CONFIGURE_URL = 'GET_CONFIGURE_URL';
export const GET_CONFIGURE_URL_SUCCESS = 'GET_CONFIGURE_URL_SUCCESS';
export const GET_CONFIGURE_URL_FAILURE = 'GET_CONFIGURE_URL_FAILURE';

export function getConfigureUrl(solutionId) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/config_wizard_url?account_id=${selectedAccountFromState.id}&solution_id=${solutionId}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      const { url } = responseJson.data;
      console.log('getConfigureUrl ', url);
      dispatch({
        type: GET_CONFIGURE_URL_SUCCESS,
        solutionId,
        url,
      });
      return url;
    }
    dispatch({
      type: GET_CONFIGURE_URL_FAILURE,
    });
  };
}

export const GET_TRAY_IO_USER = 'GET_TRAY_IO_USER';
export const GET_TRAY_IO_USER_SUCCESS = 'GET_TRAY_IO_USER_SUCCESS';
export const GET_TRAY_IO_USER_FAILURE = 'GET_TRAY_IO_USER_FAILURE';

export function getTrayIoUser() {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/users?account_id=${selectedAccountFromState.id}`,
      {
        method: 'GET',
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      const user = responseJson.data;
      console.log('getTrayIoUser ', user);
      dispatch({
        type: GET_TRAY_IO_USER_SUCCESS,
        user,
      });
    } else {
      dispatch({
        type: GET_TRAY_IO_USER_FAILURE,
      });
    }
  };
}

export const GET_TRAY_IO_SOLUTION_INSTANCES = 'GET_TRAY_IO_SOLUTION_INSTANCES';
export const GET_TRAY_IO_SOLUTION_INSTANCES_SUCCESS =
  'GET_TRAY_IO_SOLUTION_INSTANCES_SUCCESS';
export const GET_TRAY_IO_SOLUTION_INSTANCES_FAILURE =
  'GET_TRAY_IO_SOLUTION_INSTANCES_FAILURE';

export function getTrayIoSolutionInstances() {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solution_instances?account_id=${selectedAccountFromState.id}`,
      {
        method: 'GET',
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      const { solutions } = responseJson.data;
      // console.log('getTrayIoSolutionInstances ', solutions);
      dispatch({
        type: GET_TRAY_IO_SOLUTION_INSTANCES_SUCCESS,
        solutions,
      });
    } else {
      dispatch({
        type: GET_TRAY_IO_SOLUTION_INSTANCES_FAILURE,
      });
    }
  };
}

export const CREATE_TRAY_IO_SOLUTION_INSTANCE =
  'CREATE_TRAY_IO_SOLUTION_INSTANCE';
export const CREATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS =
  'CREATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS';
export const CREATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE =
  'CREATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE';

export function createTrayIoSolutionInstance(solutionId, instanceName) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solution_instance?account_id=${selectedAccountFromState.id}`,
      {
        method: 'POST',
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
        body: JSON.stringify({
          solution_id: solutionId,
          instance_name: instanceName,
        }),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      const { instance } = responseJson.data;
      console.log('createTrayIoSolutionInstance', instance);
      dispatch({
        type: CREATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
        instance,
      });
    } else {
      dispatch({
        type: CREATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE,
      });
    }
  };
}

export const UPDATE_TRAY_IO_SOLUTION_INSTANCE =
  'UPDATE_TRAY_IO_SOLUTION_INSTANCE';
export const UPDATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS =
  'UPDATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS';
export const UPDATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE =
  'UPDATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE';

export function updateTrayIoSolutionInstance(
  instanceId,
  instanceName,
  enabled
) {
  // console.log('updateTrayIoSolutionInstance1', instanceId, instanceName, enabled);
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solution_instance/${instanceId}?account_id=${selectedAccountFromState.id}`,
      {
        method: 'POST',
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
        body: JSON.stringify({
          instance_name: instanceName,
          enabled,
        }),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      const { instance } = responseJson.data;
      // console.log('updateTrayIoSolutionInstance2 result', instance);
      dispatch({
        type: UPDATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
        instance,
      });
    } else {
      dispatch({
        type: UPDATE_TRAY_IO_SOLUTION_INSTANCE_FAILURE,
      });
    }
  };
}

export const DESTROY_TRAY_IO_SOLUTION_INSTANCE =
  'DESTROY_TRAY_IO_SOLUTION_INSTANCE';
export const DESTROY_TRAY_IO_SOLUTION_INSTANCE_SUCCESS =
  'DESTROY_TRAY_IO_SOLUTION_INSTANCE_SUCCESS';
export const DESTROY_TRAY_IO_SOLUTION_INSTANCE_FAILURE =
  'DESTROY_TRAY_IO_SOLUTION_INSTANCE_FAILURE';

export function destroyTrayIoSolutionInstance(instanceId) {
  console.log('destroyTrayIoSolutionInstance', instanceId);
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solution_instance/${instanceId}?account_id=${selectedAccountFromState.id}`,
      {
        method: 'DELETE',
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      // const { instance } = responseJson.data;
      console.log('destroyTrayIoSolutionInstance result', responseJson);
      dispatch({
        type: DESTROY_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
        instanceId,
      });
    } else {
      dispatch({
        type: DESTROY_TRAY_IO_SOLUTION_INSTANCE_FAILURE,
      });
    }
  };
}

export const GET_TRAY_IO_SOLUTIONS_SUCCESS = 'GET_TRAY_IO_SOLUTIONS_SUCCESS';
export const GET_TRAY_IO_SOLUTIONS_FAILURE = 'GET_TRAY_IO_SOLUTIONS_FAILURE';

export function getAvailableSolutionsForAccount() {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solutions/available?account_id=${selectedAccountFromState.id}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();

      dispatch({
        type: GET_TRAY_IO_SOLUTIONS_SUCCESS,
        solutions: responseJson.data.solutions,
      });
    } else {
      console.error('Error getting the solutions');
      dispatch({
        type: GET_TRAY_IO_SOLUTIONS_FAILURE,
      });
    }
  };
}

export const POST_CONFIGURE_SOLUTION_INSTANCE_SUCCESS =
  'POST_CONFIGURE_SOLUTION_INSTANCE_SUCCESS';

export function postConfigureSolutionInstance(solutionInstanceId, userId) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: selectedAccountFromState,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/trayio/solution_instance/${solutionInstanceId}/post_configure`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
        body: JSON.stringify({
          user_id: userId,
          account_id: selectedAccountFromState.id,
        }),
      }
    );

    if (response.status === 200) {
      const responseJson = await response.json();

      dispatch({
        type: POST_CONFIGURE_SOLUTION_INSTANCE_SUCCESS,
        solutionInstance: responseJson.data.solutionInstance,
      });
    }
  };
}
