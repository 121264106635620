import { combineReducers } from 'redux';
import { getInnerProp } from '../../library/functions';
import {
  REQUEST_DONORS,
  RECEIVE_EMPTY_DONORS,
  RECEIVE_DONORS,
  SELECTED_DONOR,
  RECEIVE_EXPORT,
  SET_DONOR_QUERY,
  SET_DONORS_CACHED_QUERY,
  CLEAR_DONORS,
  CLEAR_SELECTED_DONOR,
  UPDATE_CACHED_DONOR,
} from './donors.actions';

export function selectedDonor(state = {}, action) {
  switch (action.type) {
    case SELECTED_DONOR:
      return action.donor;
    default:
      return state;
  }
}

export function receiveDonorQuery(state = {}, action) {
  switch (action.type) {
    case SET_DONOR_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function exportMessage(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPORT:
      return action.message;
    default:
      return state;
  }
}

function donors(
  state = {
    isFetching: false,
    items: [],
    summary: {},
    orderBy: {},
    pages: {},
    offset: null,
    cachedQuery: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_DONORS:
      return { ...state, isFetching: true };
    case RECEIVE_DONORS: {
      let page = 0;
      if (action.offset > 0) {
        page = action.offset / 20;
      }
      return {
        ...state,
        isFetching: false,
        items: action.donors,
        summary: action.summary,
        query: action.query,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
        pages: {
          ...state.pages,
          [page]: {
            items: action.donors,
            invalidateAfter: action.invalidTimestamp,
          },
          currentPage: page,
        },
        offset: action.offset,
      };
    }
    case RECEIVE_EMPTY_DONORS:
      return {
        ...state,
        isFetching: false,
        pages: {
          ...state.pages,
          currentPage: action.currentPage,
        },
      };
    case CLEAR_DONORS:
      return { ...state, summary: {}, pages: {}, offset: null };
    case CLEAR_SELECTED_DONOR:
      return { ...state, selectedDonor: {} };
    case SET_DONORS_CACHED_QUERY:
      return { ...state, cachedQuery: action.cachedQuery };
    case UPDATE_CACHED_DONOR: {
      const { currentPage } = state.pages;
      const items = getInnerProp(state, `pages.${currentPage}.items`) || [];
      const invalidateAfter =
        getInnerProp(state, `pages.${currentPage}.invalidateAfter`) ||
        new Date().valueOf();
      const newItems = items.map(i =>
        i.id === action.donor.id ? action.donor : i
      );

      if (currentPage === undefined || currentPage === null) return state;

      return {
        ...state,
        pages: {
          ...state.pages,
          [currentPage]: {
            items: newItems,
            invalidateAfter,
          },
        },
      };
    }
    default:
      return state;
  }
}

export function donorsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_DONORS:
    case RECEIVE_EMPTY_DONORS:
    case REQUEST_DONORS:
    case CLEAR_DONORS:
    case CLEAR_SELECTED_DONOR:
    case SET_DONORS_CACHED_QUERY:
    case UPDATE_CACHED_DONOR:
      return {
        ...state,
        [action.account_identifier]: donors(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const donorReducer = combineReducers({
  donorsByAccount,
  selectedDonor,
  receiveDonorQuery,
  exportMessage,
});

export default donorReducer;
