import * as Yup from 'yup';

const AccountValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Account Name is required')
    .min(3, 'Must be at least 3 characters')
    .max(100, 'Must be less than 100 characters'),
  agreed_to_terms: Yup.bool()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

export default AccountValidationSchema;
