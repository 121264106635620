/* eslint-disable prettier/prettier */
import { fetchSubscription } from '../subscriptions/subscriptions.actions';
import { receiveFail } from '../donations/donations.actions';
import { rebuildApiHeaders } from '../../library/functions';
import { fetchUserSettings } from '../my-profile/my-profile.actions';
import { HEADERS } from '../../library/constants';

export const REQUEST_ACCOUNTS = 'REQUEST_ACCOUNTS';
export const RECEIVE_ACCOUNTS = 'RECEIVE_ACCOUNTS';
export const SET_USER = 'SET_USER';
export const SELECTED_ACCOUNT = 'SELECTED_ACCOUNT';
export const IS_DONOR = 'IS_DONOR';
export const INVALIDATE_ACCOUNT = 'INVALIDATE_ACCOUNT';
export const RECEIVE_HUBSPOT_TOKEN = 'RECEIVE_HUBSPOT_TOKEN';
export const INVALIDATE_PARTNER_ACCOUNTS = 'INVALIDATE_PARTNER_ACCOUNTS';
export const REQUEST_PARTNER_ACCOUNTS = 'REQUEST_PARTNER_ACCOUNTS';
export const RECEIVE_PARTNER_ACCOUNTS = 'RECEIVE_PARTNER_ACCOUNTS';

export function receiveHubspotToken(json) {
  const tokenObject = {token:json.data, email:json.params?.email}
  localStorage.setItem('hubspotTokenObject', JSON.stringify(tokenObject));
  return {
    type: RECEIVE_HUBSPOT_TOKEN,
    token: tokenObject,
  };
}

export function selectedAccount(account) {
  localStorage.setItem('selected_account', '');
  localStorage.setItem('selected_account', JSON.stringify(account));
  return {
    type: SELECTED_ACCOUNT,
    account,
  };
}

export function isDonor(value) {
  localStorage.setItem('isDonor', value);
  return {
    type: IS_DONOR,
    value,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function invalidateAccount(account) {
  return {
    type: INVALIDATE_ACCOUNT,
    account,
  };
}

export function requestAccounts(user) {
  return {
    type: REQUEST_ACCOUNTS,
    user,
  };
}

export function receiveAccounts(user, json) {
  return {
    type: RECEIVE_ACCOUNTS,
    user,
    accounts: json.data,
    receivedAt: Date.now(),
  };
}

export function requestPartnerAccounts(user, json) {
  return {
    type: REQUEST_PARTNER_ACCOUNTS,
    user,
  };
}

export function invalidatePartnerAccounts(account) {
  return {
    type: INVALIDATE_ACCOUNT,
    account,
  };
}

export function receivePartnerAccounts(user, json) {
  return {
    type: RECEIVE_PARTNER_ACCOUNTS,
    user,
    accounts: json.data,
    account_identifier: json.params.unique_identifier,
    receivedAt: Date.now(),
  };
}

export function fetchHubspotToken(email, headers) {
  // console.log('fetchHubspotToken', {email})
  if(!email) return;
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/hubspot/user_token?email=${email}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15')
      }
    ).then(function(response) {
      if (response.status !== 200) {
        // console.log('fetch error', response);
      } else {
        return response
          .json()
          .then(json => dispatch(receiveHubspotToken(json)));
      }
    });
}

export function fetchAccounts(user) {
  return dispatch => {
    dispatch(
      requestAccounts(
        rebuildApiHeaders(user)
      )
    );

    return new Promise(async resolve => {
      try {
        const accountsResponse = await fetch(`${process.env.DONATELY_API_V2_URL}/accounts/mine.json?limit=100`,
          {
            method: 'GET',
            json: true,
            rejectUnauthorized: false,
            headers: rebuildApiHeaders(user, '2021-11-15')
          });

        if (accountsResponse.status !== 200) {
          console.log(`error ${accountsResponse}`);
        } 
        else {
          const accounts = await accountsResponse.json();
          // If user has no accounts but it has donations, it's a person created through auth0 without an account.

          dispatch(receiveAccounts(user, accounts));
          
          let currentSelectedAccount = null;
          
          if (!localStorage.getItem('selected_account') && accounts.data.length !== 0) {
            currentSelectedAccount = accounts.data[0] || null;
            dispatch(selectedAccount(currentSelectedAccount));
          } 
          else {
            currentSelectedAccount = JSON.parse(localStorage.getItem('selected_account'));
            dispatch(selectedAccount(currentSelectedAccount));
          }

          resolve(currentSelectedAccount);
        }
      } catch(err) {
        console.error('an error presented ', err);
      }
    });
  };
}

export function fetchPartnerAccounts(parentAccount, user) {
  return dispatch => {
    dispatch(
      requestPartnerAccounts(
        rebuildApiHeaders(user)
      )
    );

    return new Promise(async resolve => {
      try {
        const accountsResponse = await fetch(`${process.env.DONATELY_API_V2_URL}/accounts/${parentAccount.id}/referrals.json?limit=100`,
          {
            method: 'GET',
            json: true,
            rejectUnauthorized: false,
            headers: rebuildApiHeaders(user, '2021-11-15'),
          });
        if (accountsResponse.status !== 200) {
          console.log('fetchPartnerAccounts status error', {accountsResponse});
        } 
        else {
          const accounts = await accountsResponse.json();
          dispatch(receivePartnerAccounts(user, accounts));
        }
      } catch(err) {
        console.error('fetchPartnerAccounts error', {err});
      }
    });
  };
}

export function fetchAccount(user, subdomain) {
  if (!subdomain) {
    return;
  }

  return dispatch =>
    fetch(`${process.env.DONATELY_API_V2_URL}/accounts/${subdomain}.json`, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(user),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }

      return response.json().then(json => {
        // TODO: Update AppContext with the new selected account
        dispatch(selectedAccount(json.data));
      });
    });
}

export function shouldFetchAccounts(state, user) {
  const accounts = state.accountReducer.accountsByUser[user];
  if (!accounts) {
    return true;
  }
  if (accounts.isFetching || (accounts.items && accounts.items.length > 0)) {
    return false;
  }
  return accounts.didInvalidate;
}

export function fetchAccountsIfNeeded(user) {
  return (dispatch, getState) => {
    if (shouldFetchAccounts(getState(), user)) {
      return dispatch(fetchAccounts(user));
    }
  };
}

export function updateSource(user, subscriptionId, updateValues) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/subscriptions/${subscriptionId}/payment_sources`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
        body: JSON.stringify(updateValues),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(
          () =>
            dispatch(
              fetchSubscription(
                '',
                user,
                subscriptionId
              )
            ),
          dispatch(receiveFail('CC successfully updated.'))
        );
    });
}

export function updateLastLogin(user) {
  return dispatch =>
    fetch(`${process.env.DONATELY_API_V2_URL}/people/update_last_login`, {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(user),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error', response);
      } else {
        return response
          .json()
          .then(() => localStorage.setItem('lastLoginSent', 'true'));
      }
    });
}

export function pushToCRM(user, account) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${account.id}/push_to_crm`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
        body: JSON.stringify({ crm_name: 'hubspot' }),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        // console.log('pushToCRM error', response);
      } else {
        return response
          .json()
          .then(() => localStorage.setItem('pushToCRM', 'true'));
      }
    });
}

export function createAccount(user, accountValues, refetchUser = false) {
  return async (dispatch, getState) => {
    const createAccountResponse = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts.json`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
        body: JSON.stringify(accountValues),
      }
    );

    const authHeaders = Object.assign(HEADERS, {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    });

    const createAccountJson = await createAccountResponse.json();
    if (createAccountResponse.status !== 200) {
      return Promise.reject(new Error(createAccountJson.message));
    }

    if (refetchUser) {
      await dispatch(fetchUserSettings(user));
    }

    dispatch(selectedAccount(createAccountJson.data));

    /*
        let's not push to CRM for now, because were doing it from the API as well.
        2024/08/20
      */        
    // const newState = getState();
    // return dispatch(pushToCRM(authHeaders, newState.accountReducer.selectedAccount));
  }
}
