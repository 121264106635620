import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Form, Formik } from 'formik';
import { connect, useSelector } from 'react-redux';
import history from '../../../history';
import { useAppContext } from '../../AppContext';
import FormikInput from '../../library/components/formik/Input/index';
import { ACCOUNT_CHANGE_EVENT } from '../../EventManager';
import Button from '../../library/components/Button';
import Toggle from '../../library/components/formik/Toggle';
import { createAccount } from './user-accounts.actions';
import AccountValidationSchema from './validators/AccountValidationSchema';
import Modal from '../../library/components/modal.component';

const CreateNewAccountForm = ({ dispatch }) => {
  const { userHeaders, eventManager, selectedAccount } = useAppContext();

  const currentUser = useSelector(state => state.myProfileReducer.userSettings);
  const { error } = useSelector(state => state.donationReducer.receiveFail);
  const [modal, setModal] = useState('');

  useEffect(() => {
    if (error) {
      setModal(error);
    }
  }, [error]);

  const showError = err => {
    let message =
      'Unable to create your Account, please try again or contact support';
    if (err.response) {
      message = err.response.data ? err.response.data.message : message;
    }
    const error = {
      text: 'Error',
      message,
      type: 'error',
    };
    setModal(error);
  };

  const handleSubmit = async formValues => {
    try {
      const settingsUpdate = {
        ...formValues,
        email: currentUser.email,
        meta_data: {
          how_did_you_hear_about_us: 'duplicate',
        },
      };

      dispatch(createAccount(userHeaders, settingsUpdate));
      eventManager.addListener(ACCOUNT_CHANGE_EVENT, data => {
        history.push({ pathname: '/dashboard', state: { newAccount: true } });
      });
    } catch (err) {
      showError(err);
    }
  };

  const initialValues = {
    title: '',
    agreed_to_terms: '',
  };
  return (
    <div data-testid="create_new_account_page">
      <div className="donations-page page-content inline-block pl-6">
        <div className="h-24" />
        <div className="shadow-lg flex-col rounded-lg bg-white mr-4 mb-4 p-8">
          <div className="text-xl lg:text-2xl mb-4">
            <p className="font-semibold w-4/6 py-8 px-6">Create New Account</p>
          </div>
          <div className="rounded bg-grey-lighter p-4 md:px-6 md:pt-6 md:pb-2 mb-4">
            <p className="w-4/6 text-base mb-4 font-light">
              Complete the form below to create a new Donately account. Once you
              create a new Donately account, you will also need to connect that
              new account to an active Stripe account. You can use the same
              Stripe account currently connected to Donately, or opt to connect
              another Stripe account.
            </p>
            <p className="w-4/6 text-base mb-4 font-light">
              Once you create this new account, you’re able to use the same
              email and password login for all accounts. You can switch between
              accounts by clicking on the organization name in the top-left hand
              corner, and selecting the account.”
            </p>
            {selectedAccount.billing.subscription_plan === 'Team' && (
              <p className="w-4/6 text-base mb-4 font-light">
                Please note, your Team Subscription is only valid for a single
                account. If you wish to utilize the Team Plan features with your
                new account, you will need to upgrade the new account as well.
              </p>
            )}
            <div className="mt-8">
              <Formik
                initialValues={initialValues}
                onSubmit={values => {
                  handleSubmit(values);
                }}
                validationSchema={AccountValidationSchema}
              >
                {({ errors, isValid, isSubmitting }) => (
                  <>
                    <Form className="w-full">
                      <div className="flex items-center flex-grow text-grey-dark text-xs">
                        <span className="uppercase">
                          Account Name (Between 4-100 characters)
                        </span>
                      </div>
                      <div className="w-1/2">
                        <FormikInput name="title" styledType="default" />
                      </div>
                      <div className="mt-4 flex flex-col">
                        <Toggle
                          name="agreed_to_terms"
                          label="I agree to Donately’s"
                          labelContainerCustomClass="flex items-center inline-flex"
                          linkText=" terms of use"
                          link="https://donately.com/terms-of-use"
                        />
                        {errors.agreed_to_terms && (
                          <div className="text-xs text-red mb-2 mt-2">
                            {errors.agreed_to_terms}
                          </div>
                        )}
                      </div>

                      <div className="mt-8 flex items-center w-full justify-between mb-6">
                        <Button
                          type="submit"
                          text="Create Account"
                          styledType="primary"
                          disabled={isSubmitting || !isValid}
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
              {modal && (
                <Modal
                  modalText={error}
                  closeFunction={() => setModal('')}
                  agreeFunction={() => setModal('')}
                  noCancel
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateNewAccountForm.propTypes = {
  dispatch: PropTypes.func,
};
export default connect()(CreateNewAccountForm);
