import React from 'react';

const SuccessSvg = () => (
  <>
    <svg
      className="h-12 w-12"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
      width="26.3px"
      height="26.3px"
      viewBox="0 0 26.3 26.3"
      style={{
        enableBackground: 'new 0 0 26.3 26.3',
      }}
    >
      <style type="text/css">
        {`.st0{fill:#f6f9fa;}`}
        {`.st2{fill:none;stroke:#3cd99e;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style>
      <circle className="st0" cx="14.4" cy="14.2" r="11.9" />
      <g id="Outline_Icons_1_">
        <g id="Outline_Icons">
          <g>
            <polyline className="st2" points="17,8.5 9.5,15.5 7,13" />
            <circle className="st2" cx="12" cy="12" r="11.5" />
          </g>
        </g>
        <g id="New_icons_1_" />
      </g>
    </svg>
  </>
);

export default SuccessSvg;
