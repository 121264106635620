import { fetchMySubscriptions } from '../my-profile/my-profile.actions';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import {
  rebuildApiHeaders,
  getInnerProp,
  isQueryChanged,
} from '../../library/functions';
import { isEmpty } from '../../library/utils';
import { CACHE_VALID_TIME } from '../../library/constants';

export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS';

export function requestSubscriptions(accountIdentifier, headers) {
  return {
    type: REQUEST_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const REQUEST_FAILED_SUBSCRIPTIONS = 'REQUEST_FAILED_SUBSCRIPTIONS';

export function requestFailedSubscriptions(accountIdentifier, headers) {
  return {
    type: REQUEST_FAILED_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const REQUEST_EXPIRING_SUBSCRIPTIONS = 'REQUEST_EXPIRING_SUBSCRIPTIONS';

export function requestExpiringSubscriptions(accountIdentifier, headers) {
  return {
    type: REQUEST_EXPIRING_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const SET_SUBSCRIPTION_QUERY = 'SET_SUBSCRIPTION_QUERY';

export function setSubscriptionQuery(query) {
  return {
    type: SET_SUBSCRIPTION_QUERY,
    query,
  };
}

export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';

export function receiveSubscriptions(accountIdentifier, json) {
  return {
    type: RECEIVE_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    orderBy: json.params,
    subscriptions: json.data,
    summary: json.summary,
    offset: json.params.offset,
    invalidTimestamp: Date.now().valueOf() + CACHE_VALID_TIME,
  };
}

export const RECEIVE_EMPTY_SUBSCRIPTIONS = 'RECEIVE_EMPTY_SUBSCRIPTIONS';

export function receiveEmptySubscriptions(accountIdentifier, json) {
  return {
    type: RECEIVE_EMPTY_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
  };
}

export const RECEIVE_FAILED_SUBSCRIPTIONS = 'RECEIVE_FAILED_SUBSCRIPTIONS';

export function receiveFailedSubscriptions(accountIdentifier, json) {
  return {
    type: RECEIVE_FAILED_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    orderBy: json.params,
    subscriptions: json.data,
    summary: json.summary,
  };
}

export const RECEIVE_EXPIRING_SUBSCRIPTIONS = 'RECEIVE_EXPIRING_SUBSCRIPTIONS';

export function receiveExpiringSubscriptions(accountIdentifier, json) {
  return {
    type: RECEIVE_EXPIRING_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
    orderBy: json.params,
    subscriptions: json.data,
    summary: json.summary,
  };
}

export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION';

export function editSubscription(subscriptionId, headers) {
  return {
    type: EDIT_SUBSCRIPTION,
    subscriptionId,
    headers,
  };
}

export const SELECTED_SUBSCRIPTION = 'SELECTED_SUBSCRIPTION';

export function selectedSubscription(subscription) {
  return {
    type: SELECTED_SUBSCRIPTION,
    subscription,
  };
}

export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';

export function receiveExport(message) {
  return {
    type: RECEIVE_EXPORT,
    message,
  };
}

export const SET_SUBSCRIPTIONS_CACHED_QUERY = 'SET_SUBSCRIPTIONS_CACHED_QUERY';

export function setSubscriptionsCachedQuery(accountIdentifier, cachedQuery) {
  return {
    type: SET_SUBSCRIPTIONS_CACHED_QUERY,
    account_identifier: accountIdentifier,
    cachedQuery,
  };
}

export const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';

export function clearSubscriptions(accountIdentifier) {
  return {
    type: CLEAR_SUBSCRIPTIONS,
    account_identifier: accountIdentifier,
  };
}

/**
 * Export Subscriptions
 * Note: This function is out of dispatch behaivor
 */
export const exportSubscriptions = (
  accountIdentifier,
  headers,
  offset = '0',
  query = {}
) => {
  const url = Queries.getSubscriptionsQuery(accountIdentifier, offset, query);

  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  });
};

export function fetchSubscriptions(
  accountIdentifier,
  headers,
  offset = '0',
  page = '1',
  query = {}
) {
  const url = Queries.getSubscriptionsQuery(accountIdentifier, offset, query);

  if (query.hasOwnProperty('export_columns')) {
    return dispatch =>
      fetch(url, {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }).then(function(response) {
        if (response.status === 200) {
          dispatch(receiveExport('success'));
        } else if (response.status === 404 || response.status === 500) {
          dispatch(receiveExport('error'));
        }
      });
  }

  return (dispatch, getState) => {
    // Clear if query changed against the cachedQuery
    let state = getState().subscriptionReducer;
    const cachedQuery = getInnerProp(
      state,
      `subscriptionsByAccount.${accountIdentifier}.cachedQuery`
    );
    if (state && cachedQuery && isQueryChanged(cachedQuery, query)) {
      dispatch(clearSubscriptions(accountIdentifier));
    }

    dispatch(requestSubscriptions(accountIdentifier, headers));

    // Only request more data when offset in state is less than requested.
    state = getState().subscriptionReducer;
    const prevOffset = getInnerProp(
      state,
      `subscriptionsByAccount.${accountIdentifier}.offset`
    );
    if (state && prevOffset >= 0) {
      // Only request new page when needed
      // Map pages to int
      const storePages = getInnerProp(
        state,
        `subscriptionsByAccount.${accountIdentifier}.pages`
      );
      const pages = Object.keys(storePages).map(p => parseInt(p));
      const currentPage = parseInt(page) - 1;

      // If page is found (note page-1) and is valid by timestamp, then retrieve the data from store
      const foundPage = pages.find(p => p === currentPage);
      const invalidateAfter = getInnerProp(
        storePages,
        `${foundPage}.invalidateAfter`
      );
      const now = new Date().valueOf();
      if (foundPage >= 0 && now <= invalidateAfter) {
        return dispatch(receiveEmptySubscriptions(accountIdentifier));
      }
    }

    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveSubscriptions(accountIdentifier, json)))
          .then(() => {
            dispatch(setSubscriptionQuery(query));
            // Updates the cached query for this account.
            dispatch(setSubscriptionsCachedQuery(accountIdentifier, query));
          });
      }
    });
  };
}

export function fetchFailedSubscriptions(
  accountIdentifier,
  headers,
  offset = '0',
  query = {}
) {
  const url = Queries.getSubscriptionsQuery(accountIdentifier, offset, query);
  return (dispatch, getState) => {
    dispatch(requestFailedSubscriptions(accountIdentifier, headers));

    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json =>
            dispatch(receiveFailedSubscriptions(accountIdentifier, json))
          );
      }
    });
  };
}

export function fetchExpiringSubscriptions(
  accountIdentifier,
  headers,
  offset = '0',
  query = {}
) {
  const url = Queries.getSubscriptionsQuery(accountIdentifier, offset, query);
  return dispatch => {
    dispatch(requestExpiringSubscriptions(accountIdentifier, headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json =>
            dispatch(receiveExpiringSubscriptions(accountIdentifier, json))
          );
      }
    });
  };
}

export function fetchSubscription(accountIdentifier, headers, subscriptionId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/subscriptions/${subscriptionId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(selectedSubscription(json.data)));
      }
    });
}

export function shouldFetchSubscriptions(state, accountIdentifier) {
  const subscriptions =
    state.subscriptionReducer.subscriptionsByAccount[accountIdentifier];
  if (!subscriptions) {
    return true;
  }
  if (subscriptions.isFetching || subscriptions.items) {
    return false;
  }
}

export function fetchSubscriptionsIfNeeded(accountIdentifier, headers) {
  return (dispatch, getState) => {
    if (shouldFetchSubscriptions(getState(), accountIdentifier)) {
      return dispatch(fetchSubscriptions(accountIdentifier, headers));
    }
  };
}

export function updateSubscription(
  accountIdentifier,
  headers,
  subscriptionId,
  updateValues
) {
  return dispatch => {
    dispatch(editSubscription(subscriptionId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/subscriptions/${subscriptionId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return dispatch(
          receiveFail(
            'Oops an error occurred while updating your recurring plan, please try again.'
          )
        );
      }
      return response
        .json()
        .then(json => dispatch(selectedSubscription(json.data)));
    });
  };
}

export function runSubPlan(headers, subscriptionId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/subscriptions/${subscriptionId}/run_now`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()

        .then(json => dispatch(selectedSubscription(json.data)));
    });
}
