import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DonatelyPage = () => (
  <div className="fa-layers fa-fw block mx-auto mb-2">
    <FontAwesomeIcon icon={['fal', 'browser']} size="lg" />
  </div>
);

export default DonatelyPage;
