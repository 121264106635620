import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, useDispatch, useSelector } from 'react-redux';
import SignupCardForm from './signup-cc-form.container';
import { hideUpgradeModal } from '../../../../authenticated/modal/modal.actions';

const UpgradeModal = () => {
  const modalElement = useRef(null);
  const {
    upgradeModal: { open: modalOpen, onUpgradeSuccess },
  } = useSelector(state => state.modalReducer);
  const dispatch = useDispatch();

  const handleOutsideClick = function(e) {
    if ((modalElement.current && modalOpen) || !modalElement.current) {
      if (modalElement.current?.contains(e.target) || !modalElement.current) {
        return;
      }
    }

    document.removeEventListener('click', handleOutsideClick);
    dispatch(hideUpgradeModal());
  };

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }
  }, [modalOpen]);

  if (!modalOpen) {
    return null;
  }

  return (
    <div className="flex fixed modal-wrapper z-40 flex">
      <div
        className="md:w-2/3 flex-col bg-white content-center rounded-lg shadow-lg p-1 m-auto"
        ref={modalElement}
      >
        <span className="flex justify-end mt-4 cursor-pointer">
          <FontAwesomeIcon
            icon={['far', 'times']}
            className="mx-4"
            onClick={() => {
              document.removeEventListener('click', handleOutsideClick);
              dispatch(hideUpgradeModal());
            }}
          />
        </span>
        <SignupCardForm
          onUpgradeSuccess={() => {
            document.removeEventListener('click', handleOutsideClick);
            if (onUpgradeSuccess) {
              onUpgradeSuccess();
            }
          }}
        />
      </div>
    </div>
  );
};

export default connect()(UpgradeModal);
