import { round } from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  PostalCodeElement,
  StripeProvider,
} from 'react-stripe-elements';
import history from '../../../../../history';
import {
  updateAccountCC,
  updateAccountSubscription,
} from '../../../../authenticated/account-settings/account-settings.actions';
import {
  hideUpgradeModal,
  showMessage,
} from '../../../../authenticated/modal/modal.actions';
import { PRICING_PLANS_INFO } from '../../../constants';
import Pill from '../../Pill';

const _InjectedStripeForm = ({ stripe, onTokenObtained }) => {
  const [submitted, setSubmitted] = useState(false);
  const {
    upgradeModal: { plan, isYearly },
  } = useSelector(state => state.modalReducer);
  const dispatch = useDispatch();

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    if (stripe) {
      stripe.createToken().then(payload => {
        if (payload.error) {
          setSubmitted(false);
          dispatch(showMessage(payload.error.message, 'error'));
        } else {
          onTokenObtained(payload, isYearly, plan);
        }
      });
    } else {
      console.log('stripe JS has not loaded yet!');
    }
  };

  const price =
    PRICING_PLANS_INFO[plan][`${isYearly ? 'yearly' : 'monthly'}_price`];

  return (
    <form className="sm:p-10" onSubmit={handleSubmit}>
      <div className="border border-grey-light rounded p-4 w-full my-1 text-left">
        <div className="border-b border-grey-light relative pb-4 mb-2">
          <div className="inline-block">
            {isYearly ? (
              <>
                <span className="text-xl text-black">{`$${price}`}</span>
                /year&nbsp;
                <span className="font-light">{`($${round(
                  price / 12.0
                )}/monthly)`}</span>
              </>
            ) : (
              <>
                <span className="text-xl text-black">{`$${price}`}</span>/month
              </>
            )}
          </div>
        </div>

        <label className="uppercase text-grey-dark text-xxs py-2 font-light">
          Card number
        </label>
        <CardNumberElement />
        <div>
          <div className="w-1/3 inline-block pr-2">
            <label className="uppercase text-grey-dark text-xxs py-2 font-light">
              Expiration date
            </label>
            <CardExpiryElement />
          </div>
          <div className="w-1/3 inline-block pr-2">
            <label className="uppercase text-grey-dark text-xxs py-2 font-light">
              CVC
            </label>
            <CardCVCElement />
          </div>
          <div className="w-1/3 inline-block">
            <label className="uppercase text-grey-dark text-xxs py-2 font-light">
              Zip Code
            </label>
            <PostalCodeElement />
          </div>
        </div>
        <div className="text-grey text-xxs">
          <svg
            className="fill-current h-5 w-5 align-middle pr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              className="heroicon-ui"
              d="M7 10V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h2zm2 0h6V7a3 3 0 0 0-6 0v3zm-4 2v8h14v-8H5zm7 2a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1z"
            />
          </svg>
          <div className="align-middle inline-block">
            SSL Encrypted and Secure
          </div>
        </div>
      </div>

      <div className="mt-4 mb-4 text-center text-grey-darker flex flex-col items-center justify-center">
        {!isYearly ? (
          <p className="mb-4">
            {`You will be charged $${price} immediately, and your subscription will
              renew on the same day each month. You may cancel at any time from
              the Billing section within your Donately dashboard. We do not offer
              refunds on our month-to-month plan.`}
          </p>
        ) : (
          <p className="mb-4">
            {`You will be charged $${price} immediately, and your subscription will
              renew on the same day each year. You may cancel at any time from the
              Billing section within your Donately dashboard. If you cancel within
              your first 30 days, we will issue a refund for the full amount, less
              one month of service ($${round(price / 12.0, 1)}).`}
          </p>
        )}
        <div className="inline-flex items-baseline">
          <input type="checkbox" required className="mr-2" />
          <label>
            I acknowledge the statements above and have reviewed the
            <a
              className="ml-1 link-dntly-blue"
              href="https://donately.com/terms-of-use#subscription-terms"
              target="_blank"
              rel="noreferrer"
            >
              full subscription terms & cancellation policy.
            </a>
          </label>
        </div>
      </div>
      <div className="flex justify-center">
        {submitted && (
          <Pill styledType="upgrade-outlined-transparent">
            Change Subscription
          </Pill>
        )}
        {!submitted && (
          <button className="dntly-btn-upsell mt-2">Change Subscription</button>
        )}
      </div>
    </form>
  );
};

const InjectedStripeForm = connect()(injectStripe(_InjectedStripeForm));

const SignupCardForm = ({ onUpgradeSuccess }) => {
  const stripeKey = process.env.DONATELY_LIVE_PUBLIC_KEY;
  const dispatch = useDispatch();
  const { selectedAccount, storeUser } = useSelector(
    state => state.accountReducer
  );

  const updateCreditCard = async (payload, isYearly, plan) => {
    const ccUpdate = {
      payment_auth: JSON.stringify({ stripe_token: payload.token.id }),
      card_hash: {
        exp_month: payload.token.card.exp_month,
        exp_year: payload.token.card.exp_year,
        cc_last4: payload.token.card.last4,
        zip: payload.token.card.address_zip,
      },
      account_id: selectedAccount.id,
    };
    const paymentInterval = isYearly ? 'yearly' : 'monthly';
    await dispatch(updateAccountCC(selectedAccount.id, storeUser, ccUpdate));
    const [success, errorMessage] = await dispatch(
      updateAccountSubscription(
        selectedAccount.id,
        storeUser,
        plan,
        paymentInterval
      )
    );
    if (success) {
      await dispatch(hideUpgradeModal());
      await dispatch(showMessage('You have successfully updated your account'));

      if (onUpgradeSuccess) {
        onUpgradeSuccess();
        return;
      }

      if (history.location.search) {
        history.push({ pathname: '/dashboard' });
      }
    } else {
      dispatch(showMessage(errorMessage, 'error'));
    }
  };

  return (
    <div className="w-full">
      {stripeKey ? (
        <StripeProvider apiKey={stripeKey} locale="us">
          <Elements>
            <InjectedStripeForm onTokenObtained={updateCreditCard} />
          </Elements>
        </StripeProvider>
      ) : (
        <>{console.error('Missing Stripe Public Key')}</>
      )}
    </div>
  );
};

export default connect()(SignupCardForm);
