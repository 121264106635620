import React from 'react';
import { PropTypes } from 'prop-types';

const LoadingMessage = ({ message }) => (
  <>
    <div className="shadow-lg rounded-lg bg-white mr-4 mb-4 pb-4 loading-div flex items-center">
      <div className="m-auto loading-heart flex-col text-center">
        <div className="text-grey-darker text-2xl mt-8 no-padding">
          {message}
        </div>
        <div className="loader loader-with-message">&nbsp;</div>
      </div>
    </div>
  </>
);

LoadingMessage.defaultProps = {
  message: 'Loading...',
};

LoadingMessage.propTypes = {
  message: PropTypes.string,
};

export default LoadingMessage;
