import React from 'react';
import { Auth0Lock } from 'auth0-lock';
import { Auth0Client } from '@auth0/auth0-spa-js';
import history from '../history';
import {
  scheduleTask,
  timeUntilExpires,
  consoleLog,
} from '../app/library/functions';
import { sessionInitialize } from '../app/unauthenticated/actions/session-initialize.actions';

const IDLE_TIME_MINUTES = 60; // how long in between attempts at refreshing the id_token

/*

  TODO:
   - swap out @auth0/auth0-spa-js and use @auth0/auth0-react instead
   - docs: https://auth0.com/docs/quickstart/spa/react

*/

const authClient = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  client_id: process.env.AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
});

// lock = new Auth0LockPasswordless(process.env.AUTH0_CLIENT_ID, process.env.AUTH0_DOMAIN, {
const authLock = new Auth0Lock(
  process.env.AUTH0_CLIENT_ID,
  process.env.AUTH0_DOMAIN,
  {
    container: 'auth-lock',
    auth: {
      redirectUrl: process.env.AUTH0_REDIRECT_URL,
      responseType: 'token id_token',
      params: {
        scope: 'openid profile email',
      },
    },
    allowedConnections: [process.env.AUTH0_CONNECTION],
    configurationBaseUrl: 'https://cdn.auth0.com',
    theme: {
      primaryColor: '#3cd99e',
      foregroundColor: '#fff',
      logo:
        'https://s3.amazonaws.com/assets.donately.com/donately-logo-square-200px.png',
    },
    allowLogin: true,
    allowShowPassword: true,
    allowForgotPassword: true,
    initialScreen: 'login',
    allowSignUp: true,
    mustAcceptTerms: true,
    rememberLastLogin: false,
    signUpLink: '/universal_signup',
    // signUpLink: 'https://donately.com/signup',
    languageDictionary: {
      title: 'Donately',
      signUpTerms:
        'I Accept the Donately <a href="https://donately.com/terms">Terms Of Use</a>',
    },
    // socialButtonStyle: 'small',
    additionalSignUpFields: [
      {
        type: 'select',
        name: 'marketing_source',
        placeholder: 'How did you hear about us?',
        required: true,
        options: [
          { value: 'Google/Search Engine', label: 'Google/Search Engine' },
          { value: 'Social Media', label: 'Social Media' },
          { value: 'Blog Post', label: 'Blog Post' },
          { value: 'Capterra', label: 'Capterra' },
          { value: 'Stripe', label: 'Stripe' },
          {
            value: 'Referral / Word-of-Mouth',
            label: 'Referral / Word-of-Mouth',
          },
          {
            value: "Another Nonprofit's Website",
            label: "Another Nonprofit's Website",
          },
          { value: 'Other', label: 'Other' },
        ],
      },
    ],
  }
);

export default class Auth {
  lock = authLock;

  client = authClient;

  handleUniversalLoginAuthentication = async (token, user, dispatch) => {
    const tokenPayload = {
      id_token: token,
      ...user,
    };
    const { appState } = await this.client.handleRedirectCallback();
    // first perform a full clear from previous data
    this.manageLocalStorage('clear');

    // use the new data
    this.manageLocalStorage('authenticated', tokenPayload, async function() {
      const redirectTo = appState.returnTo;
      const sessionInitializeResult = await dispatch(sessionInitialize());

      history.replace({ pathname: redirectTo || sessionInitializeResult });
    });
  };

  handleAuthentication(dispatch) {
    this.lock.on('authenticated', (authResult, error) => {
      if (authResult && authResult.accessToken) {
        const auth = new Auth();
        const tokenPayload = Object.assign(authResult.idTokenPayload, {
          access_token: authResult.accessToken,
          id_token: authResult.idToken,
          refresh_token: authResult.refreshToken,
        });
        this.manageLocalStorage(
          'authenticated',
          tokenPayload,
          async function() {
            const redirectTo = await dispatch(sessionInitialize());
            history.replace({ pathname: redirectTo });
          }
        );
      } else if (error) {
        console.error('authentication error', error);
      }
    });

    this.lock.on('authorization_error', function(error) {
      const auth = new Auth();
      auth.lock.show({
        flashMessage: {
          type: 'error',
          text: error.errorDescription,
        },
      });
    });
  }

  manageLocalStorage(mode, params, callback) {
    if (mode === 'authenticated') {
      if (params.access_token) {
        localStorage.setItem('access_token', params.access_token);
      }
      if (params.refresh_token) {
        localStorage.setItem('refresh_token', params.refresh_token);
      }
      if (params.id_token) {
        localStorage.setItem('id_token', params.id_token);
      }

      if (params.exp) {
        localStorage.setItem('expires_at', params.exp);
      }

      if (params.name) {
        localStorage.setItem('profile_name', params.name);
      }
      if (params.picture) {
        localStorage.setItem('profile_picture', params.picture);
      }
      if (params.email) {
        localStorage.setItem('profile_email', params.email);
      }
      if (params.nickname) {
        localStorage.setItem('profile_nickname', params.nickname);
      }
      if (params.sub) {
        localStorage.setItem('profile_id', params.sub.split('|')[1]);
      }

      localStorage.setItem('apiUpdate', 'true');

      const appMetadata = params['https://dntly-dash.com/app_metadata'];
      const apiToken =
        (appMetadata && appMetadata.token) ||
        (appMetadata && appMetadata.api_token);

      //   consoleLog(
      //   ' ---> manageLocalStorage',
      //   { mode },
      //   { apiToken },
      //   { appMetadata },
      //   { params }
      // );

      if (apiToken) {
        localStorage.setItem('dntly_api_token', apiToken);
      }

      // appMetadata: {
      //   "connected_to_multiple_accounts": true,
      //   "has_admin_roles": true,
      //   "donately_team_member": true,
      //   "api_token": "dxxxxxxxxxyyyyyyyyyyy",
      //   "last_sign_in": 1699535943000
      // }
    } else {
      // logout

      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');

      localStorage.removeItem('expires_at');

      // keep these in locaStorage so the dashboard profile stuff doesn't flash off on logout
      // localStorage.removeItem('profile_name')
      // localStorage.removeItem('profile_picture')

      localStorage.removeItem('profile_email');
      localStorage.removeItem('profile_nickname');
      localStorage.removeItem('profile_id');

      localStorage.removeItem('apiUpdate');

      localStorage.removeItem('dntly_api_token');

      localStorage.removeItem('selected_account');
      localStorage.removeItem('isDonor');
      localStorage.removeItem('stripeRedirectLocation');
      localStorage.removeItem('PaypalRedirectLocation');
      localStorage.removeItem('lastLoginSent');
      localStorage.removeItem('pushToCRM');
      // localStorage.removeItem('closedNotifications');
    }

    // consoleLog(
    //   `manageLocalStorage mode: ${mode}`,
    //   { params },
    //   { codeArea: 'verbose' }
    // );

    if (typeof callback === 'function') {
      callback();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  logout(location) {
    consoleLog(`logout | location: ${location}`, { codeArea: 'auth' });
    const auth = new Auth();
    auth.manageLocalStorage('logout');
    auth.lock.logout({ returnTo: process.env.AUTH0_HOME_URL });
  }

  login() {
    this.lock.show();
    this.handleAuthentication();
  }

  getUserInfo(authToken, callback) {
    consoleLog('getUserInfo', authToken, { codeArea: 'auth' });
    this.lock.getUserInfo(authToken, callback);
  }

  //
  // One method of re-verifying the Auth session is to create a task to run periodically.  This is not working 100% for some reason.
  //
  monitorAuth() {
    const minuteInterval = IDLE_TIME_MINUTES;
    consoleLog(` -|*|-> monitorAuth | minuteInterval: ${minuteInterval}`, {
      codeArea: 'auth',
    });
    scheduleTask(this.verifyAuthSession, minuteInterval * 60 * 1000);
  }

  verifyAuthSession() {
    const auth = new Auth();
    auth.lock.checkSession({}, function(error, authResult) {
      if (authResult) {
        consoleLog(
          ' -> verifyAuthSession --> Success! ',
          { authResult },
          { codeArea: 'auth' }
        );
        const tokenPayload = Object.assign(authResult.idTokenPayload, {
          access_token: authResult.accessToken,
          refresh_token: authResult.refreshToken,
          id_token: authResult.idToken,
        });
        auth.manageLocalStorage('authenticated', tokenPayload);
      } else {
        consoleLog(' -> verifyAuthSession --> err.code', error && error.code, {
          codeArea: 'auth',
        });
        if (error && error.code === 'login_required') {
          auth.warnBeforeLogOut();
        }
      }
    });
  }

  // is there a way to pop a modal whereever the user is?
  warnBeforeLogOut() {
    consoleLog('you will be logged out in 5 seconds');
    scheduleTask(this.logout, 5000);
  }

  secondsToAuthExpiration(location) {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const tokens =
      localStorage.getItem('id_token') && localStorage.getItem('access_token');
    let diff = 0;
    const testmode = location === 'Nav Button';

    if (expiresAt) {
      diff = timeUntilExpires(expiresAt, testmode ? 'readable' : 'seconds');
    }

    //
    // 2nd option for re-verifying the Auth session - use this if the monitorAuth() method doesn't seem to be working
    //
    if (!testmode && tokens && diff < IDLE_TIME_MINUTES * 60) {
      consoleLog(
        'verifyAuthSession............',
        { testmode },
        { tokens },
        { diff },
        { idleTime: IDLE_TIME_MINUTES * 60 },
        { codeArea: 'auth' }
      );
      this.verifyAuthSession();
    }

    return diff;
  }

  getUserAfterLogin() {
    this.client.handleRedirectCallback().then(appState => {
      consoleLog('handleRedirectCallback', { appState }, { codeArea: 'auth' });
    });

    this.client.getUser().then(user => {
      const auth = new Auth();
      auth.client.getTokenSilently().then(tokenResult => {
        consoleLog('getTokenSilently', { tokenResult }, { codeArea: 'auth' });
        if (tokenResult) {
          auth.manageLocalStorage('authenticated', {
            access_token: tokenResult,
          });
        }
      });
      auth.client.getIdTokenClaims().then(claimResult => {
        consoleLog('getIdTokenClaims', { claimResult }, { codeArea: 'auth' });
        if (claimResult) {
          const tokenPayload = Object.assign(claimResult, {
            id_token: claimResult.__raw,
          });
          auth.manageLocalStorage('authenticated', tokenPayload);
          history.replace('/');
        }
      });
    });
  }

  redirectToAuth0() {
    this.client.loginWithRedirect({
      redirect_uri: process.env.AUTH0_REDIRECT_URL,
    });
  }
}
