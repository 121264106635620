import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ManageRecurring = () => (
  <div className="fa-layers fa-fw block mx-auto mb-2">
    <FontAwesomeIcon icon={['fal', 'money-bill']} size="lg" />
    <FontAwesomeIcon
      icon={['far', 'repeat']}
      transform="shrink-4"
      style={{ background: '#f1f5f8', transform: 'translate(15px, 7px)' }}
    />
  </div>
);

export default ManageRecurring;
