import React, { useContext } from 'react';

const AppContext = React.createContext();

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

export default AppContext;

// custom hook to create App context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(
      'useAppContext could not be used out of AppContext.Provider'
    );
  }
  return context;
};
