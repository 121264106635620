import { combineReducers } from 'redux';
import { GET_HTML_CONTENTS_SUCCESS } from './html-contents.actions';

export function htmlContents(
  state = {
    items: [],
  },
  action
) {
  switch (action.type) {
    case GET_HTML_CONTENTS_SUCCESS:
      return {
        ...state,
        items: action.htmlContents,
      };
    default:
      return state;
  }
}

const htmlContentsReducer = combineReducers({
  htmlContents,
});

export default htmlContentsReducer;
