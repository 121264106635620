import React from 'react';
import Button from './Button';

const ProductionFallbackContainer = () => (
  <div className="page-content inline-block pl-6">
    <div>
      <div className="md:h-24 md:pb-0 pb-4 flex items-center mobile-flex-col w-full">
        <div className="ml-4 md:pb-0 pb-4 pt-6 text-3xl text-black font-medium" />
      </div>
    </div>
    <div className="shadow-lg flex rounded-lg bg-white mr-4 mb-4 p-8">
      <div className="flex flex-col w-full items-center">
        <div className="text-xl font-bold text-dntly-warning mb-8">
          There was an error loading your data.
        </div>
        <div className="flex">
          <Button
            styledType="warning-outlined"
            size="large"
            customClassName="mx-4"
            onClick={() => {
              window.location.reload();
            }}
          >
            <div className="p-2 flex justify-center items-center text-base font-bold">
              Refresh page
            </div>
          </Button>
          <Button
            styledType="warning-outlined"
            size="large"
            customClassName="mx-4"
            onClick={() => {
              window.location.href = '/dashboard';
            }}
          >
            <div className="p-2 flex justify-center items-center text-base font-bold">
              Refresh Dashboard
            </div>
          </Button>
          <Button
            styledType="warning-outlined"
            size="large"
            customClassName="mx-4"
            onClick={() => {
              window.HubSpotConversations.widget.open();
            }}
          >
            <div className="p-2 flex justify-center items-center text-base font-bold">
              Contact Support
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ProductionFallbackContainer;
