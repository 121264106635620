import { combineReducers } from 'redux';
import {
  REQUEST_DONATIONS,
  RECEIVE_DONATIONS,
  RECEIVE_EMPTY_DONATIONS,
  SELECTED_DONATION,
  INVALIDATE_DONATION,
  RECEIVE_EXPORT,
  SET_DONATION_QUERY,
  RECEIVE_FAIL,
  CLEAR_DONATIONS,
  SET_CACHED_QUERY,
  RESET_DONATIONS_DATA,
  RECEIVE_DONATIONS_BY_QUERY,
  REQUEST_DONATIONS_BY_QUERY,
  UPDATE_CACHED_DONATION,
} from './donations.actions';

export function selectedDonation(state = {}, action) {
  switch (action.type) {
    case SELECTED_DONATION:
      return action.donation;
    default:
      return state;
  }
}

export function receiveDonationQuery(state = {}, action) {
  switch (action.type) {
    case SET_DONATION_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function exportMessage(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPORT:
      return action.message;
    default:
      return state;
  }
}

export function receiveFail(state = { error: '', uniqueID: '' }, action) {
  switch (action.type) {
    case RECEIVE_FAIL:
      return { ...state, error: action.error, uniqueID: action.uniqueID };
    default:
      return state;
  }
}

function donations(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    pages: {},
    summary: {},
    orderBy: {},
    offset: null,
    cachedQuery: {},
    byQuery: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_DONATION:
      return { ...state, didInvalidate: true };
    case REQUEST_DONATIONS:
      return { ...state, isFetching: true, didInvalidate: false };
    case REQUEST_DONATIONS_BY_QUERY:
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          isFetching: true,
        },
      };
    case RECEIVE_DONATIONS:
      let page = 0;
      if (action.offset && action.offset > 0) {
        page = action.offset / 20;
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.donations,
        pages: {
          ...state.pages,
          [page]: {
            items: action.donations,
            invalidateAfter: action.invalidTimestamp,
          },
          currentPage: page,
        },
        summary: action.summary,
        orderBy: action.order_by,
        lastUpdated: action.receivedAt,
        offset: action.offset,
      };
    case RECEIVE_DONATIONS_BY_QUERY:
      return {
        ...state,
        byQuery: {
          items: action.donations,
          summary: action.summary,
          orderBy: action.order_by,
        },
      };
    case RECEIVE_EMPTY_DONATIONS:
      return {
        ...state,
        isFetching: false,
        pages: {
          ...state.pages,
          currentPage: action.currentPage,
        },
      };
    case CLEAR_DONATIONS:
      return { ...state, summary: {}, pages: {}, offset: null };
    case RESET_DONATIONS_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: [],
        pages: {},
        summary: {},
        orderBy: {},
        lastUpdated: null,
        offset: null,
      };
    case SET_CACHED_QUERY:
      return { ...state, cachedQuery: action.cachedQuery };
    case UPDATE_CACHED_DONATION:
      const { currentPage } = state.pages;
      const items = getInnerProp(state, `pages.${currentPage}.items`) || [];
      const invalidateAfter =
        getInnerProp(state, `pages.${currentPage}.invalidateAfter`) ||
        new Date().valueOf();
      const newItems = items.map(i =>
        i.id === action.donation.id ? { ...i, ...action.donation } : i
      );

      if (currentPage === undefined || currentPage === null) return state;

      return {
        ...state,
        pages: {
          ...state.pages,
          [currentPage]: {
            items: newItems,
            invalidateAfter,
          },
        },
      };
    default:
      return state;
  }
}

export function donationsByAccount(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_DONATION:
    case RECEIVE_DONATIONS:
    case RECEIVE_DONATIONS_BY_QUERY:
    case RECEIVE_EMPTY_DONATIONS:
    case REQUEST_DONATIONS:
    case REQUEST_DONATIONS_BY_QUERY:
    case CLEAR_DONATIONS:
    case SET_CACHED_QUERY:
    case RESET_DONATIONS_DATA:
    case UPDATE_CACHED_DONATION:
      return {
        ...state,
        [action.account_identifier]: donations(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const donationReducer = combineReducers({
  donationsByAccount,
  selectedDonation,
  receiveDonationQuery,
  exportMessage,
  receiveFail,
});

export default donationReducer;
