import { combineReducers } from 'redux';
import { REQUEST_INSIGHTS, RECEIVE_INSIGHTS } from './dashboard.actions';

export function insights(
  state = {
    isFetching: false,
    insights: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_INSIGHTS:
      return { ...state, isFetching: true };
    case RECEIVE_INSIGHTS:
      return {
        ...state,
        isFetching: false,
        insights: action.insights,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export function insightsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_INSIGHTS:
    case REQUEST_INSIGHTS:
      return {
        ...state,
        [action.account_identifier]: insights(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const dashboardReducer = combineReducers({
  insightsByAccount,
});

export default dashboardReducer;
