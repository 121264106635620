import { combineReducers } from 'redux';
import {
  RECEIVE_WEBHOOKS,
  REQUEST_WEBHOOKS,
  SET_WEBHOOK_QUERY,
  SET_WEBHOOK_ATTEMPTS_QUERY,
  RECEIVE_WEBHOOK_ATTEMPTS,
  REQUEST_WEBHOOK_ATTEMPTS,
  SELECTED_WEBHOOK,
  GET_TRAY_IO_SOLUTIONS_SUCCESS,
  GET_TRAY_IO_SOLUTION_INSTANCES_SUCCESS,
  GET_TRAY_IO_USER_SUCCESS,
  UPDATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
  CREATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
  DESTROY_TRAY_IO_SOLUTION_INSTANCE_SUCCESS,
  POST_CONFIGURE_SOLUTION_INSTANCE_SUCCESS,
} from './integrations.actions';

export function selectedWebhook(state = {}, action) {
  switch (action.type) {
    case SELECTED_WEBHOOK:
      return action.webhook;
    default:
      return state;
  }
}

export function receiveWebhookQuery(state = {}, action) {
  switch (action.type) {
    case SET_WEBHOOK_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function receiveWebhookAttemptsQuery(state = {}, action) {
  switch (action.type) {
    case SET_WEBHOOK_ATTEMPTS_QUERY:
      return action.query;
    default:
      return state;
  }
}

function webhooks(
  state = {
    isFetching: false,
    items: [],
    summary: {},
    orderBy: '',
  },
  action
) {
  switch (action.type) {
    case REQUEST_WEBHOOKS:
      return { ...state, isFetching: true };
    case RECEIVE_WEBHOOKS:
      return {
        ...state,
        isFetching: false,
        items: action.webhooks,
        summary: action.summary,
        orderBy: action.orderBy,
      };
    default:
      return state;
  }
}

function attempts(
  state = {
    isFetching: false,
    items: [],
    summary: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_WEBHOOK_ATTEMPTS:
      return { ...state, isFetching: true };
    case RECEIVE_WEBHOOK_ATTEMPTS:
      return {
        ...state,
        isFetching: false,
        items: action.attempts,
        summary: action.summary,
        orderBy: action.orderBy,
      };
    default:
      return state;
  }
}

export function webhooksByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_WEBHOOKS:
    case REQUEST_WEBHOOKS:
      return {
        ...state,
        [action.account_identifier]: webhooks(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

export function webhookAttempts(state = {}, action) {
  switch (action.type) {
    case RECEIVE_WEBHOOK_ATTEMPTS:
    case REQUEST_WEBHOOK_ATTEMPTS:
      return {
        ...state,
        [action.webhookID]: attempts(state[action.webhookID], action),
      };
    default:
      return state;
  }
}

export function trayIoSolutions(
  state = {
    all_solutions: [],
    active_solutions: [],
  },
  action
) {
  const parseSolutionInstance = (solutionInstance, correspondingSolution) => {
    if (correspondingSolution) {
      solutionInstance.node.tags = correspondingSolution.node?.tags;
      solutionInstance.node.customFields =
        correspondingSolution.node?.customFields;
      solutionInstance.node.description =
        correspondingSolution.node?.description;
    }

    return {
      ...solutionInstance,
      node: {
        ...solutionInstance.node,
        title: solutionInstance.node.name,
        active: true,
        instance: true,
      },
    };
  };
  switch (action.type) {
    case GET_TRAY_IO_SOLUTIONS_SUCCESS:
      return {
        ...state,
        all_solutions: action.solutions,
      };
    case GET_TRAY_IO_SOLUTION_INSTANCES_SUCCESS: {
      const activeSolutions = action.solutions || [];
      return {
        ...state,
        active_solutions: activeSolutions.map(solutionInstance => {
          const correspondingSolution = state.all_solutions.find(
            solution => solution.node.id === solutionInstance.node.solution.id
          );
          return parseSolutionInstance(solutionInstance, correspondingSolution);
        }),
      };
    }
    case GET_TRAY_IO_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS: {
      const newActiveSolutions = [...state.active_solutions];
      const editedIndex = newActiveSolutions.findIndex(
        solution => solution.node.id === action.instance.id
      );
      if (editedIndex >= 0) {
        newActiveSolutions[editedIndex].node.enabled = action.instance.enabled;
      }
      return {
        ...state,
        active_solutions: newActiveSolutions,
      };
    }
    case CREATE_TRAY_IO_SOLUTION_INSTANCE_SUCCESS: {
      const newActiveSolutions = [...state.active_solutions];
      const correspondingSolution = state.all_solutions.find(
        solution => solution.node.id === action.instance.solution.id
      );
      newActiveSolutions.push(
        parseSolutionInstance(
          {
            node: action.instance,
          },
          correspondingSolution
        )
      );

      return {
        ...state,
        active_solutions: newActiveSolutions,
      };
    }
    case DESTROY_TRAY_IO_SOLUTION_INSTANCE_SUCCESS: {
      const newActiveSolutions = [...state.active_solutions];
      const deleteIndex = newActiveSolutions.findIndex(
        solutionInstance => solutionInstance.node.id === action.instanceId
      );
      if (deleteIndex >= 0) {
        newActiveSolutions.splice(deleteIndex, 1);
      }

      return {
        ...state,
        active_solutions: newActiveSolutions,
      };
    }
    case POST_CONFIGURE_SOLUTION_INSTANCE_SUCCESS: {
      const currentActiveSolutions = [...state.active_solutions];
      const editedActiveSolutionIndex = currentActiveSolutions.findIndex(
        el => el.node.id === action.solutionInstance.id
      );
      const editedActiveSolution =
        currentActiveSolutions[editedActiveSolutionIndex];
      const newActiveSolution = {
        ...editedActiveSolution,
        node: {
          ...editedActiveSolution.node,
          ...action.solutionInstance,
        },
      };
      currentActiveSolutions.splice(
        editedActiveSolutionIndex,
        1,
        newActiveSolution
      );
      return {
        ...state,
        active_solutions: currentActiveSolutions,
      };
    }
    default:
      return state;
  }
}

const integrationReducer = combineReducers({
  webhooksByAccount,
  receiveWebhookQuery,
  receiveWebhookAttemptsQuery,
  webhookAttempts,
  selectedWebhook,
  trayIoSolutions,
});

export default integrationReducer;
