import { combineReducers } from 'redux';
import {
  REQUEST_CAMPAIGNS,
  RECEIVE_CAMPAIGNS,
  INVALIDATE_CAMPAIGN,
  SELECTED_CAMPAIGN,
  RECEIVE_EXPORT,
  SET_CAMPAIGN_QUERY,
  RECEIVE_CAMPAIGNS_RECURSIVELY,
} from './campaigns.actions';

export function selectedCampaign(state = {}, action) {
  switch (action.type) {
    case SELECTED_CAMPAIGN:
      return action.campaign;
    default:
      return state;
  }
}

export function receiveCampaignQuery(state = {}, action) {
  switch (action.type) {
    case SET_CAMPAIGN_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function exportMessage(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPORT:
      return action.message;
    default:
      return state;
  }
}

export function campaigns(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    summary: {},
    orderBy: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_CAMPAIGN:
      return { ...state, didInvalidate: true };
    case REQUEST_CAMPAIGNS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_CAMPAIGNS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.campaigns,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_CAMPAIGNS_RECURSIVELY:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.campaigns,
      };
    default:
      return state;
  }
}

export function campaignsByAccount(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_CAMPAIGN:
    case RECEIVE_CAMPAIGNS:
    case REQUEST_CAMPAIGNS:
    case RECEIVE_CAMPAIGNS_RECURSIVELY:
      return {
        ...state,
        [action.account_identifier]: campaigns(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const campaignReducer = combineReducers({
  campaignsByAccount,
  selectedCampaign,
  receiveCampaignQuery,
  exportMessage,
});

export default campaignReducer;
