import * as Queries from '../../library/query.module';
import {
  rebuildApiHeaders,
  isQueryChanged,
  getInnerProp,
} from '../../library/functions';
import { CACHE_VALID_TIME } from '../../library/constants';

export const RECEIVE_FAIL = 'RECEIVE_FAIL';

export function receiveFail(error, uniqueID) {
  return {
    type: RECEIVE_FAIL,
    error,
    uniqueID,
  };
}

export const REQUEST_DONATIONS = 'REQUEST_DONATIONS';

export function requestDonations(accountIdentifier, headers) {
  return {
    type: REQUEST_DONATIONS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const REQUEST_DONATIONS_BY_QUERY = 'REQUEST_DONATIONS_BY_QUERY';

export function requestDonationsByQuery(accountIdentifier, headers) {
  return {
    type: REQUEST_DONATIONS_BY_QUERY,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const SET_DONATION_QUERY = 'SET_DONATION_QUERY';

export function setDonationQuery(query) {
  return {
    type: SET_DONATION_QUERY,
    query,
  };
}

export const RECEIVE_DONATIONS = 'RECEIVE_DONATIONS';

export function receiveDonations(accountIdentifier, json) {
  return {
    type: RECEIVE_DONATIONS,
    account_identifier: accountIdentifier,
    donations: json.data,
    summary: json.summary,
    order_by: json.params,
    offset: json.params.offset,
    invalidTimestamp: Date.now().valueOf() + CACHE_VALID_TIME,
  };
}

export const RECEIVE_DONATIONS_BY_QUERY = 'RECEIVE_DONATIONS_BY_QUERY';

export function receiveDonationsByQuery(accountIdentifier, json) {
  return {
    type: RECEIVE_DONATIONS_BY_QUERY,
    account_identifier: accountIdentifier,
    donations: json.data,
    summary: json.summary,
    order_by: json.params,
  };
}

export const RESET_DONATIONS_DATA = 'RESET_DONATIONS_DATA';

export function resetDonationsData(accountIdentifier, json) {
  return {
    type: RESET_DONATIONS_DATA,
    account_identifier: accountIdentifier,
  };
}

export const RECEIVE_EMPTY_DONATIONS = 'RECEIVE_EMPTY_DONATIONS';

export function receiveEmptyDonations(accountIdentifier, currentPage) {
  return {
    type: RECEIVE_EMPTY_DONATIONS,
    account_identifier: accountIdentifier,
    currentPage,
  };
}

export const EDIT_DONATION = 'EDIT_DONATION';

export function editDonation(donationId, headers) {
  return {
    type: EDIT_DONATION,
    donationId,
    headers,
  };
}

export const SELECTED_DONATION = 'SELECTED_DONATION';

export function selectedDonation(donation) {
  return {
    type: SELECTED_DONATION,
    donation,
  };
}

export const INVALIDATE_DONATION = 'INVALIDATE_DONATION';

export function invalidateDonation(donation) {
  return {
    type: INVALIDATE_DONATION,
    donation,
  };
}

export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';

export function receiveExport(message) {
  return {
    type: RECEIVE_EXPORT,
    message,
  };
}

export const SET_CACHED_QUERY = 'SET_CACHED_QUERY';

export function setCachedQuery(accountIdentifier, cachedQuery) {
  return {
    type: SET_CACHED_QUERY,
    account_identifier: accountIdentifier,
    cachedQuery,
  };
}

export const CLEAR_DONATIONS = 'CLEAR_DONATIONS';

export function clearDonations(accountIdentifier) {
  return {
    type: CLEAR_DONATIONS,
    account_identifier: accountIdentifier,
  };
}

export const UPDATE_CACHED_DONATION = 'UPDATE_CACHED_DONATION';

/**
 * Updates a cached donation from the requested pages in store.
 * It will update with the properties provided in donation (non destructive).
 *
 * @param {string} accountIdentifier - is the account identifier
 * @param {any} donation - is the donation to be updated
 * @returns the built action.
 */
export function updateCachedDonation(accountIdentifier, donation) {
  return {
    type: UPDATE_CACHED_DONATION,
    account_identifier: accountIdentifier,
    donation,
  };
}

/**
 * Export Donations
 * Note: This function is out of dispatch behaivor
 */
export const exportDonations = (
  accountIdentifier,
  headers,
  offset = '0',
  query = {}
) => {
  const url = Queries.getDonationsQuery(accountIdentifier, offset, query);

  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  });
};

/**
 * Fetchs the donations by donor. It will store on a sepparated structure to avoid issues wich
 * pagination data caching.
 *
 * Note: this information would not be cached and will always trigger a fetch.
 *
 * @param {string} accountIdentifier - is the account identifier to store the information
 * @param {any} headers - is the headers object to be used for this request
 * @param {any} query - is the query parameters to be build into the url.
 */
export const fetchDonationsByQuery = (query = {}) => (dispatch, getState) => {
  const {
    accountReducer: {
      storeUser: headers,
      selectedAccount: { id: accountIdentifier },
    },
  } = getState();
  const url = Queries.getDonationsQuery(accountIdentifier, 0, query);
  dispatch(requestDonationsByQuery(accountIdentifier, headers));

  return fetch(url, {
    type: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  }).then(function(response) {
    if (response.status !== 200) {
      return response.json().then(json => dispatch(receiveFail(json.message)));
    }

    return response.json().then(json => {
      dispatch(receiveDonationsByQuery(accountIdentifier, json));
    });
  });
};

export function fetchDonationsV2(
  accountIdentifier,
  headers,
  offset = '0',
  page,
  query = {}
) {
  const url = Queries.getDonationsQuery(accountIdentifier, offset, query);
  // console.log('fetchDonationsV2', {offset}, {page}, {query})
  if (query.hasOwnProperty('export_columns')) {
    return dispatch =>
      fetch(url, {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }).then(function(response) {
        if (response.status === 200) {
          dispatch(receiveExport('success'));
        } else if (response.status === 404 || response.status === 500) {
          dispatch(receiveExport('error'));
        }
      });
  }
  return (dispatch, getState) => {
    // Clear if query changed against the cachedQuery
    let state = getState().donationReducer;
    const cachedQuery = getInnerProp(
      state,
      `donationsByAccount.${accountIdentifier}.cachedQuery`
    );
    if (state && cachedQuery && isQueryChanged(cachedQuery, query)) {
      dispatch(clearDonations(accountIdentifier));
    }

    dispatch(requestDonations(accountIdentifier, headers));

    // Only request more data when offset in state is less than requested.
    state = getState().donationReducer;
    const prevOffset = getInnerProp(
      state,
      `donationsByAccount.${accountIdentifier}.offset`
    );
    if (state && prevOffset >= 0) {
      // Only request new page when needed
      // Map pages to int
      const storePages = getInnerProp(
        state,
        `donationsByAccount.${accountIdentifier}.pages`
      );
      const pages = Object.keys(storePages).map(p => parseInt(p));
      const currentPage = parseInt(page) - 1;

      // If page is found (note page-1) and is valid by timestamp, then retrieve the data from store
      const foundPage = pages.find(p => p === currentPage);
      const invalidateAfter = getInnerProp(
        storePages,
        `${foundPage}.invalidateAfter`
      );
      const now = new Date().valueOf();
      if (foundPage >= 0 && now <= invalidateAfter) {
        return dispatch(receiveEmptyDonations(accountIdentifier, currentPage));
      }
    }

    return fetch(url, {
      type: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }

      return response.json().then(json => {
        dispatch(receiveDonations(accountIdentifier, json));
        dispatch(setDonationQuery(query));
        // Updates the cached query for this account.
        dispatch(setCachedQuery(accountIdentifier, query));
      });
    });
  };
}

export function fetchDonationV2(accountIdentifier, headers, donationId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/donations/${donationId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return console.log('error');
      }
      return response
        .json()
        .then(json => dispatch(selectedDonation(json.data)));
    });
}

export function updateDonation(
  accountIdentifier,
  headers,
  donationId,
  updateValues
) {
  return dispatch => {
    dispatch(editDonation(donationId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/donations/${donationId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json => dispatch(selectedDonation(json.data)))
        .then(dispatch(fetchDonationsV2(accountIdentifier, headers)));
    });
  };
}

export function shouldFetchDonations(state, accountIdentifier) {
  const donations = state.donationReducer.donationsByAccount[accountIdentifier];
  if (!donations) {
    return true;
  }
  if (donations.isFetching || donations.items) {
    return false;
  }
}

export function fetchDonationsIfNeeded(accountIdentifier, headers, offset) {
  return (dispatch, getState) => {
    if (shouldFetchDonations(getState(), accountIdentifier)) {
      return dispatch(fetchDonationsV2(accountIdentifier, headers, offset));
    }
  };
}

export function refundDonation(
  accountIdentifier,
  headers,
  donationId,
  refundReason
) {
  return dispatch => {
    let url = `${process.env.DONATELY_API_V2_URL}/donations/${donationId}/refund.json?account_id=${accountIdentifier}`;
    if (refundReason) {
      url = `${url}&refund_reason=${refundReason}`;
    }
    return fetch(url, {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        receiveFail(
          'You have successfully refunded the donation',
          json.data.id
        );
        dispatch(selectedDonation(json.data));
      });
    });
  };
}

export function newDonation(
  accountIdentifier,
  headers,
  donationType,
  donationValues,
  stripeToken
) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/donations.json?account_id=${accountIdentifier}&donation_type=${donationType}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
        body: JSON.stringify(donationValues),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json =>
          dispatch(
            receiveFail(
              'You have successfully created your donation',
              json.data.id
            )
          )
        )
        .then(json => dispatch(fetchDonationsV2(accountIdentifier, headers)));
    });
}

export function getStatesList(country) {
  return dispatch =>
    fetch(`https://util.donately.com/countries/${country}/states.json`, {
      method: 'GET',
      accept: 'application/json',
    }).then(function(response) {
      if (response.status !== 200) {
        return response.json().then(json => console.log('error', json.message));
      }
      return response.json();
    });
}
