import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../../../history';
import { isRestrictedIfMatchesSubscription } from '../../../library/functions';
import { PRICING_PLANS } from '../../../library/constants';

class SideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: window.location.pathname.split('/')[1],
      activeProfile: window.location.pathname.split('/')[2],
    };
  }

  componentDidMount() {
    this.unlisten = history.listen(() => {
      this.setState({ active: window.location.pathname.split('/')[1] });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  activeClick = element => {
    this.setState({ active: element });
    this.setState({ activeProfile: element });
  };

  render() {
    const { active, activeProfile } = this.state;
    const { isDonor, selectedAccount, currentUserSettings } = this.props;
    const isRestrictedStartupFree = isRestrictedIfMatchesSubscription(
      currentUserSettings,
      selectedAccount,
      [PRICING_PLANS.Free, PRICING_PLANS['Discontinued-Free']]
    );

    return (
      <div className="hidden lg:inline-block xl:w-48 w-12 font-normal pb-6 h-screen fixed text-xs sidenav">
        {isDonor === 'false' && (
          <ul className="sidenav-ul list-reset text-sm">
            <li className="sidenav-link">
              <Link
                to="/dashboard"
                className={active === 'dashboard' ? 'active' : ''}
                onClick={() => this.activeClick('dashboard')}
              >
                <FontAwesomeIcon
                  icon={['far', 'home']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Dashboard
                </div>
              </Link>
            </li>

            {isRestrictedStartupFree && (
              <li className="sidenav-link">
                <Link
                  to="/settings/billing"
                  className={active === 'upgrade' ? 'active' : ''}
                  onClick={() => this.activeClick('upgrade')}
                >
                  <FontAwesomeIcon
                    icon={['far', 'trophy']}
                    className="ml-2 sidenav-icon-width sidenav-link dntly-purple"
                  />
                  <div className="inline-block ml-1 my-auto leading-none dntly-purple">
                    Upgrade
                  </div>
                </Link>
              </li>
            )}

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                CRM
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/donors"
                id="donors"
                className={active === 'donors' ? 'active' : ''}
                onClick={() => this.activeClick('donors')}
              >
                <FontAwesomeIcon
                  icon={['far', 'user']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Donors
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/donations?status=processed,pending,refunded"
                className={active === 'donations' ? 'active' : ''}
                onClick={() => this.activeClick('donations')}
              >
                <FontAwesomeIcon
                  icon={['far', 'usd-circle']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Donations
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/recurring_plans?status=active"
                className={active === 'recurring_plans' ? 'active' : ''}
                onClick={() => this.activeClick('recurring_plans')}
              >
                <FontAwesomeIcon
                  icon={['far', 'repeat']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Recurring Plans
                </div>
              </Link>
            </li>

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                FUNDRAISING
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/forms"
                className={active === 'forms' ? 'active' : ''}
                onClick={() => this.activeClick('forms')}
              >
                <FontAwesomeIcon
                  icon={['far', 'file-alt']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Forms
                  <div className="ml-2 hidden text-3xs text-black p-1 lg:inline-block rounded bg-green align-top absolute">
                    NEW
                  </div>
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/campaigns?status=published,hidden"
                className={active === 'campaigns' ? 'active' : ''}
                onClick={() => this.activeClick('campaigns')}
              >
                <FontAwesomeIcon
                  icon={['far', 'flag']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Campaigns
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/fundraisers?status=published"
                className={active === 'fundraisers' ? 'active' : ''}
                onClick={() => this.activeClick('fundraisers')}
              >
                <FontAwesomeIcon
                  icon={['far', 'user-friends']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Fundraisers
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/text-engagement"
                className={active === 'text-engagement' ? 'active' : ''}
                onClick={() => this.activeClick('text-engagement')}
              >
                <FontAwesomeIcon
                  icon={['far', 'mobile']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none relative">
                  Text Engagement
                </div>
              </Link>
            </li>

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                SETTINGS
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/settings/account_settings"
                className={active === 'settings' ? 'active' : ''}
                onClick={() => this.activeClick('settings')}
              >
                <FontAwesomeIcon
                  icon={['far', 'cog']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Account Settings
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/integrations/api"
                className={active === 'integrations' ? 'active' : ''}
                onClick={() => this.activeClick('integrations')}
              >
                <FontAwesomeIcon
                  icon={['far', 'plug']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Integrations
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/partners"
                className={active === 'partners' ? 'active' : ''}
                onClick={() => this.activeClick('partners')}
              >
                <FontAwesomeIcon
                  icon={['far', 'handshake']}
                  className="ml-2 sidenav-icon-width"
                  flip="horizontal"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Partners
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/tasks"
                className={active === 'tasks' ? 'active' : ''}
                onClick={() => this.activeClick('tasks')}
              >
                <FontAwesomeIcon
                  icon={['far', 'cog']}
                  className="ml-2 sidenav-icon-width sidenav-link text-dntly-yellow-darker"
                  flip="horizontal"
                />
                <div className="inline-block ml-1 my-auto leading-none text-dntly-yellow-darker">
                  Tasks
                </div>
              </Link>
            </li>
          </ul>
        )}

        {isDonor === 'true' && (
          <ul className="sidenav-ul donor-sidenav list-reset text-sm">
            <li className="sidenav-link">
              <Link
                to="/my_profile/dashboard"
                className={activeProfile === 'dashboard' ? 'active' : ''}
                onClick={() => this.activeClick('dashboard')}
              >
                <FontAwesomeIcon
                  icon={['far', 'home']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  Dashboard
                </div>
              </Link>
            </li>

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                TRANSACTIONS
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/my_profile/donations"
                className={activeProfile === 'donations' ? 'active' : ''}
                onClick={() => this.activeClick('donations')}
              >
                <FontAwesomeIcon
                  icon={['far', 'usd-circle']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  My Donations
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/my_profile/recurring_plans"
                className={activeProfile === 'recurring_plans' ? 'active' : ''}
                onClick={() => this.activeClick('recurring_plans')}
              >
                <FontAwesomeIcon
                  icon={['far', 'repeat']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  My Recurring Plans
                </div>
              </Link>
            </li>

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                FUNDRAISING
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/my_profile/fundraisers"
                className={activeProfile === 'fundraisers' ? 'active' : ''}
                onClick={() => this.activeClick('fundraisers')}
              >
                <FontAwesomeIcon
                  icon={['far', 'user-friends']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  My Fundraisers
                </div>
              </Link>
            </li>

            <li>
              <div className="block text-grey-dark text-2xs mb-2 mt-6 px-4">
                SETTINGS
              </div>
            </li>

            <li className="sidenav-link">
              <Link
                to="/my_profile/settings"
                className={activeProfile === 'settings' ? 'active' : ''}
                onClick={() => this.activeClick('settings')}
              >
                <FontAwesomeIcon
                  icon={['far', 'cog']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  My Settings
                </div>
              </Link>
            </li>

            <li className="sidenav-link">
              <Link
                to="/my_profile/tax_report"
                className={activeProfile === 'tax_report' ? 'active' : ''}
                onClick={() => this.activeClick('tax_report')}
              >
                <FontAwesomeIcon
                  icon={['far', 'file-alt']}
                  className="ml-2 sidenav-icon-width"
                />
                <div className="inline-block ml-1 my-auto leading-none">
                  My Tax Report
                </div>
              </Link>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isDonor, selectedAccount } = state.accountReducer;
  const currentUserSettings = state.myProfileReducer.userSettings;

  return {
    isDonor,
    selectedAccount,
    currentUserSettings,
  };
}

export default connect(mapStateToProps)(SideNav);
