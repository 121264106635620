export const defaultThemes = {
  classic: {
    '-PrimaryColor': '#e3ebed',
    '-PrimaryFontFamily': "'Avenir','Arial','Verdana','Helvetica',sans-serif",
    '-PrimaryFontSize': '14px',
    '-FormBackgroundColor': '#ffffff',

    '-LabelFontColor': '#4f4f4f',
    '-LabelFontSize': '14px',
    '-LabelTextTransform': 'none',

    '-FieldFontColor': '#4f4f4f',
    '-FieldBackgroundColor': '#ffffff',
    '-FieldBorderColor': '#b8c5c9',
    '-FieldFontSize': '14px',
    '-FieldBorderWidth': '1px',
    '-FieldBorderRadius': '3px',

    '-ButtonFontColor': '#595959',
    '-ButtonFontColorHover': '#595959',
    '-ButtonFontSize': '14px',
    '-ButtonBackgroundColor': '#f7f7f7',
    '-ButtonBackgroundColorHover': '#e3ebed',
    '-ButtonBorderColor': '#e3ebed',
    '-ButtonBorderWidth': '1px',
    '-ButtonBorderRadius': '3px',

    '-SubmitButtonFontColor': '#ffffff',
    '-SubmitButtonFontColorHover': '#ffffff',
    '-SubmitButtonBackgroundColor': '#2dae63',
    '-SubmitButtonBackgroundColorHover': '#289a57',
    '-SubmitButtonBorderColor': '#e3ebed',
    '-SubmitButtonBorderWidth': '0px',

    '-PlaceholderTextColor': '#bfbfbf',
  },
  modern: {
    '-PrimaryColor': '#3e8fbe',
    '-LabelFontColor': '#808b8e',
    '-LabelTextTransform': 'uppercase',
    '-LabelFontSize': '11px',

    '-FieldFontColor': '#0d1a20',
    '-FieldBackgroundColor': '#f5f7f7',
    '-FieldBorderColor': '#f4f6f7',
    '-FieldBorderRadius': '5px',

    '-ButtonFontColor': '#ffffff',
    '-ButtonFontColorHover': '#cdddf0',
    '-ButtonBackgroundColor': '#3c84d9',
    '-ButtonBackgroundColorHover': '#2468b9',
    '-ButtonBorderWidth': '0px',
    '-ButtonBorderRadius': '5px',

    '-SubmitButtonFontColor': '#ffffff',
    '-SubmitButtonFontColorHover': '#d0deef',
    '-SubmitButtonBackgroundColor': '#3c84d9',
    '-SubmitButtonBackgroundColorHover': '#14529b',
  },
  monochromatic: {
    '-PrimaryColor': '#e3ebed',
  },
};

const MONOCHROMATIC = 'monochromatic';
const MODERN = 'modern';

export const FieldTypes = {
  generalOptions: {
    name: 'General Options',
    value: 'generalOptions',
    openState: false,
    editableWithTheme: [MONOCHROMATIC],
  },
  fieldsetOptions: {
    name: 'Fieldset Options',
    value: 'fieldsetOptions',
    openState: false,
    editableWithTheme: [MODERN],
  },
  labelOptions: {
    name: 'Label Options',
    value: 'labelOptions',
    openState: false,
    editableWithTheme: [MODERN],
  },
  buttonOptions: {
    name: 'Button Options',
    value: 'buttonOptions',
    openState: false,
    editableWithTheme: [MODERN],
  },
  submitButtonOptions: {
    name: 'Submit Button Options',
    value: 'submitButtonOptions',
    openState: false,
    editableWithTheme: [MODERN],
  },
};

const BORDER_WIDTH_OPTIONS = [
  { name: '0px', value: '0px' },
  { name: '1px', value: '1px' },
  { name: '2px', value: '2px' },
  { name: '3px', value: '3px' },
  { name: '4px', value: '4px' },
  { name: '5px', value: '5px' },
];
const FONT_SIZE_OPTIONS = [
  { name: '14px', value: '14px' },
  { name: '15px', value: '15px' },
  { name: '16px', value: '16px' },
  { name: '17px', value: '17px' },
  { name: '18px', value: '18px' },
  { name: '19px', value: '19px' },
  { name: '20px', value: '20px' },
];
const TEXT_TRANSFORM_OPTIONS = [
  { name: 'None', value: 'none' },
  { name: 'Capitalize', value: 'capitalize' },
  { name: 'Uppercase', value: 'uppercase' },
  { name: 'Lowercase', value: 'lowercase' },
];
const BORDER_RADIUS_OPTIONS = [
  { name: '0px', value: '0px' },
  { name: '1px', value: '1px' },
  { name: '2px', value: '2px' },
  { name: '3px', value: '3px' },
  { name: '4px', value: '4px' },
  { name: '5px', value: '5px' },
];

export const menuOptions = {
  '-PrimaryColor': {
    displayName: 'Primary Color',
    inputType: 'color',
    fieldType: FieldTypes.generalOptions.value,
    editableWithTheme: [MONOCHROMATIC],
  },
  '-PrimaryFontFamily': {
    displayName: 'Font Family',
    inputType: 'text',
    fieldType: FieldTypes.generalOptions.value,
    editableWithTheme: false,
    options: window.Donately?.styles?.fontsList?.map(font => ({
      name: font,
      value: font,
    })),
  },
  '-PrimaryFontSize': {
    displayName: 'Font Size',
    inputType: 'text',
    fieldType: FieldTypes.generalOptions.value,
    editableWithTheme: false,
    options: FONT_SIZE_OPTIONS,
  },
  '-FormBackgroundColor': {
    displayName: 'Form Background Color',
    inputType: 'color',
    fieldType: FieldTypes.generalOptions.value,
    editableWithTheme: false,
  },
  '-LabelFontColor': {
    displayName: 'Label Font Color',
    inputType: 'color',
    fieldType: FieldTypes.labelOptions.value,
    editableWithTheme: [MODERN],
  },
  '-LabelFontSize': {
    displayName: 'Label Font Size',
    inputType: 'text',
    fieldType: FieldTypes.labelOptions.value,
    editableWithTheme: [MODERN],
    options: FONT_SIZE_OPTIONS,
  },
  '-LabelTextTransform': {
    displayName: 'Label Text',
    inputType: 'text',
    fieldType: FieldTypes.labelOptions.value,
    editableWithTheme: [MODERN],
    options: TEXT_TRANSFORM_OPTIONS,
  },
  '-FieldFontColor': {
    displayName: 'Field Font Color',
    inputType: 'color',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: [MODERN],
  },
  '-FieldFontSize': {
    displayName: 'Field Font Size',
    inputType: 'text',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: false,
    options: FONT_SIZE_OPTIONS,
  },
  '-FieldBackgroundColor': {
    displayName: 'Field Background Color',
    inputType: 'color',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: [MODERN],
  },
  '-FieldBorderColor': {
    displayName: 'Field Border Color',
    inputType: 'color',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: [MODERN],
  },
  '-FieldBorderWidth': {
    displayName: 'Border Line Width',
    inputType: 'text',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: false,
    options: BORDER_WIDTH_OPTIONS,
  },
  '-FieldBorderRadius': {
    displayName: 'Border Line Radius',
    inputType: 'text',
    fieldType: FieldTypes.fieldsetOptions.value,
    editableWithTheme: [MODERN],
    options: BORDER_RADIUS_OPTIONS,
  },
  '-ButtonFontColor': {
    displayName: 'Button Font Color',
    inputType: 'color',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-ButtonFontColorHover': {
    displayName: 'Button Font Color Hover',
    inputType: 'color',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-ButtonFontSize': {
    displayName: 'Button Font Size',
    inputType: 'text',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: false,
    options: FONT_SIZE_OPTIONS,
  },
  '-ButtonBackgroundColor': {
    displayName: 'Button Color',
    inputType: 'color',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-ButtonBackgroundColorHover': {
    displayName: 'Button Color Hover',
    inputType: 'color',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-ButtonBorderColor': {
    displayName: 'Button Border Color',
    inputType: 'color',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: false,
  },
  '-ButtonBorderWidth': {
    displayName: 'Button Border Line Width',
    inputType: 'text',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: false,
    options: BORDER_WIDTH_OPTIONS,
  },
  '-ButtonBorderRadius': {
    displayName: 'Button Border Line Radius',
    inputType: 'text',
    fieldType: FieldTypes.buttonOptions.value,
    editableWithTheme: [MODERN],
    options: BORDER_RADIUS_OPTIONS,
  },
  '-SubmitButtonFontColor': {
    displayName: 'Submit Button Font Color',
    inputType: 'color',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-SubmitButtonFontColorHover': {
    displayName: 'Submit Button Font Color Hover',
    inputType: 'color',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-SubmitButtonBackgroundColor': {
    displayName: 'Submit Button Color',
    inputType: 'color',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-SubmitButtonBackgroundColorHover': {
    displayName: 'Submit Button Color Hover',
    inputType: 'color',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: [MODERN],
  },
  '-SubmitButtonBorderColor': {
    displayName: 'Submit Button Border Color',
    inputType: 'color',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: false,
  },
  '-SubmitButtonBorderWidth': {
    displayName: 'Submit Button Border Line Width',
    inputType: 'text',
    fieldType: FieldTypes.submitButtonOptions.value,
    editableWithTheme: false,
    options: BORDER_WIDTH_OPTIONS,
  },
  '-PlaceholderTextColor': {
    displayName: 'Placeholder Text Color',
    inputType: 'color',
    fieldType: FieldTypes.generalOptions.value,
    editableWithTheme: false,
  },
};
