import React from 'react';

const DevelopmentFallbackContainer = ({ error, componentStack }) => (
  <div className="page-content inline-block pl-6">
    <div>
      <div className="md:h-24 md:pb-0 pb-4 flex items-center mobile-flex-col w-full">
        <div className="ml-4 md:pb-0 pb-4 text-3xl text-white font-medium" />
      </div>
    </div>
    <div style={{ margin: '20px' }}>
      <h3>Ooops! something went wrong.</h3>
      <p>Hopefully you can back up, and try again.</p>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        <pre style={{ fontSize: '.8em' }}>{componentStack}</pre>
      </details>
    </div>
  </div>
);

export default DevelopmentFallbackContainer;
