import { get } from 'lodash';
import { fetchUserSettings } from '../../authenticated/my-profile/my-profile.actions';
import {
  fetchAccounts,
  isDonor as isDonorAction,
  pushToCRM,
  setUser,
  updateLastLogin,
} from '../../authenticated/user-accounts/user-accounts.actions';
import { HEADERS } from '../../library/constants';
import { rebuildApiHeaders, consoleLog } from '../../library/functions';

export const checkDonorStatus = (user, selectedAccount) => (
  dispatch,
  getState
) =>
  new Promise(async resolve => {
    const state = getState();
    let donations = get(
      state,
      `donationReducer.donationsByAccount.${selectedAccount?.id}.items`,
      []
    );

    if (donations.length === 0) {
      const donationsResponse = await fetch(
        `${process.env.DONATELY_API_V2_URL}/donations/mine.json?limit=1`,
        {
          method: 'GET',
          json: true,
          rejectUnauthorized: false,
          headers: rebuildApiHeaders(user),
        }
      );
      if (donationsResponse.status !== 200) {
        console.error(
          `Error obtaining donations response ${donationsResponse}`
        );
        return false;
      }
      await donationsResponse.json().then(({ data }) => {
        donations = data;
      });
    }
    if (!localStorage.getItem('isDonor')) {
      dispatch(
        isDonorAction(
          !selectedAccount && donations.length > 0 ? 'true' : 'false'
        )
      );
    } else {
      dispatch(
        isDonorAction(JSON.parse(localStorage.getItem('isDonor')).toString())
      );
    }

    resolve();
  });

export const checkFundraiserStatus = user => () =>
  new Promise(async resolve => {
    const fundraisersResponse = await fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/mine.json?limit=1`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
      }
    );
    if (fundraisersResponse.status !== 200) {
      console.error(
        `Error obtaining fundraisers response ${fundraisersResponse}`
      );
      resolve();
    } else {
      const fundraisers = await fundraisersResponse.json();
      localStorage.setItem(
        'hasFundraisers',
        JSON.stringify(fundraisers.data.length > 0)
      );
      resolve(fundraisers.data);
    }
  });

function getSessionData(hasBeenInitialized) {
  return (dispatch, getState) =>
    new Promise(async resolve => {
      // set the headers for authorization
      const authHeaders = Object.assign(HEADERS, {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      });
      await dispatch(setUser(authHeaders));
      await dispatch(fetchUserSettings(authHeaders));
      let state = getState();
      const user = state.accountReducer.storeUser;
      const selectedAccount = await dispatch(fetchAccounts(user));
      await dispatch(checkDonorStatus(user, selectedAccount));
      await dispatch(checkFundraiserStatus(user, selectedAccount));

      if (!localStorage.getItem('lastLoginSent') && !hasBeenInitialized) {
        dispatch(updateLastLogin(authHeaders));
      }

      /*
        let's not push to CRM for now, because were doing it from the API as well.
        2024/08/20
       */
      // if (
      //   !localStorage.getItem('pushToCRM') &&
      //   authHeaders &&
      //   selectedAccount &&
      //   !hasBeenInitialized
      // ) {
      //   dispatch(pushToCRM(authHeaders, selectedAccount));
      // }

      /*

        We need to set up some variables based on the user
          - is_donor (true/false) - do they have any personal donations?
          - is_fundraiser (true/false) - do they have any personal fundraisers?
          - is_admin (true/false) - are they an admin on any Donately accounts?

        We need to set up some variables based on the current account
          - stripe_connect_id - do we have a Stripe Account associated with this Donately account?

        Redirect Rules
        1) if is_admin=false && is_donor=false && is_fundraiser=false
          - then we assume they are setting up an account so send them to /onboarding

        2) if is_admin=false && (is_donor=true || is_fundraiser=true)
          - then we assume they are a donor and send them to /my_profile

        3) if is_admin=true && is_donor=true/OR/false && is_fundraiser=true/OR/false
          a) if stripe_connect_id=true
            - then we assume they are an admin and send them to the /dashboard
          b) if stripe_connect_id=false
            - then we assume they are an admin and send them to the /onboarding Step 2 so they can connect to Stripe

        */

      // refetch state with last changes
      state = getState();
      const isAdmin = get(
        state,
        'myProfileReducer.userSettings.has_admin_roles',
        false
      );
      const isDonor = JSON.parse(localStorage.getItem('isDonor'));
      const isFundraiser = JSON.parse(localStorage.getItem('hasFundraisers'));
      const stripeConnectId = get(
        selectedAccount,
        'processors.stripe.stripe_connect_id',
        null
      );
      const partnerType = get(selectedAccount, 'partner_type', null);

      // consoleLog(
      //   'sessionInitialize & redirect',
      //   { isAdmin },
      //   { isDonor },
      //   { isFundraiser },
      //   { stripeConnectId },
      //   { partnerType }
      // );

      if (isAdmin) {
        if (stripeConnectId) {
          // account complete, redirect to dashboard
          if (partnerType && partnerType === 'partner') {
            resolve('/referrals');
          } else {
            resolve('/dashboard');
          }
        } else {
          // stripe was not connected yet, return to onboarding
          resolve('/onboarding');
        }
      } else if (isFundraiser) {
        // if it isn't admin but it's fundraiser, go to fundraiser profile
        resolve('/my_profile/fundraisers');
      } else if (isDonor) {
        // if it isn't either admin or fundraiser, but it's donor, go to donor profile
        resolve('/my_profile/donations');
      } else {
        // if it's neither of the previous, it's an account that was just set up
        resolve('/onboarding');
      }
    });
}

export function sessionInitialize() {
  return getSessionData(false);
}

export function sessionRefresh() {
  return getSessionData(true);
}

export function sessionRefreshOnboarding() {
  return async dispatch => {
    const authHeaders = Object.assign(HEADERS, {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    });
    await dispatch(setUser(authHeaders));
    return dispatch(fetchUserSettings(authHeaders));
  };
}
