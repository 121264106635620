import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faLock,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  faHome,
  faCheck,
  faUser,
  faUsdCircle,
  faRepeat,
  faFlag,
  faUserFriends,
  faFileAlt,
  faCog,
  faRedo,
  faImage,
  faSearch,
  faAngleDown,
  faAngleUp,
  faPlus,
  faMinus,
  faTimes,
  faEllipsisHAlt,
  faCreditCard,
  faExternalLink,
  faMapSigns,
  faCode,
  faAngleRight,
  faCloud,
  faPlug,
  faTrophy,
  faThumbsUp,
  faMobile,
  faExclamationCircle,
  faHandshake,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faInfoCircle,
  faUserCircle,
  faMoneyBillAlt,
  faCogs,
  faChevronCircleLeft,
  faChevronCircleRight,
  faFileInvoiceDollar,
  faBrowser,
  faUsers,
  faShareAlt,
  faMoneyBill,
  faEdit,
  faDonate,
  faCreditCardFront,
  faColumns,
  faQuestionCircle,
  faGift,
} from '@fortawesome/pro-light-svg-icons';

import { faShareNodes } from '@fortawesome/pro-light-svg-icons-v6/index.js';

import { faCcStripe } from '@fortawesome/free-brands-svg-icons';

const App = ({ children }) => {
  library.add(
    faThumbsUp,
    faLock,
    faCheck,
    faInfoCircle,
    faUserCircle,
    faQuestionCircle,
    faHome,
    faUser,
    faUsdCircle,
    faRepeat,
    faFlag,
    faUserFriends,
    faFileAlt,
    faCog,
    faRedo,
    faImage,
    faSearch,
    faAngleDown,
    faAngleUp,
    faPlus,
    faMinus,
    faTimes,
    faMoneyBillAlt,
    faCogs,
    faEllipsisHAlt,
    faChevronCircleLeft,
    faChevronCircleRight,
    faFileInvoiceDollar,
    faBrowser,
    faUsers,
    faShareAlt,
    faMoneyBill,
    faEdit,
    faDonate,
    faCreditCard,
    faExternalLink,
    faMapSigns,
    faCode,
    faCcStripe,
    faCreditCardFront,
    faSortUp,
    faSortDown,
    faAngleRight,
    faExclamationCircle,
    faColumns,
    faPlug,
    faTrophy,
    faCloud,
    faMobile,
    faGift,
    faHandshake,
    faShareNodes
  );

  return <>{children}</>;
};

App.propTypes = {
  children: PropTypes.node,
};

export default App;
