import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ fullScreen }) => (
  <div
    className={`local-loading shadow-lg rounded-lg bg-white mr-4 mb-4 pb-4 ${
      fullScreen ? 'loading-div' : ''
    } flex items-center`}
    data-testid="loading"
  >
    <div className="loader">Loading...</div>
  </div>
);

export default Loading;

Loading.defaultProps = {
  fullScreen: true,
};

Loading.propTypes = {
  fullScreen: PropTypes.bool,
};
