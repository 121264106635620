import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CampaignPage = () => (
  <div className="fa-layers fa-fw block mx-auto mb-6">
    <FontAwesomeIcon icon={['fal', 'browser']} size="2x" transform="grow-2" />
    <FontAwesomeIcon
      icon={['far', 'usd-circle']}
      transform="shrink-1"
      style={{ background: '#f1f5f8', transform: 'translate(20px, 10px)' }}
    />
  </div>
);

export default CampaignPage;
