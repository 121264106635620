import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'uuid';
import {
  scheduleTask,
  getUserName,
  getUserAvatar,
  setDashboardView,
  isRestrictedIfMatchesSubscription,
} from '../../../library/functions';
import history from '../../../../history';
import { PRICING_PLANS } from '../../../library/constants';

export default class TopNav extends Component {
  constructor(props) {
    super(props);
    this.toggleLeftNav = this.toggleLeftNav.bind(this);
    this.toggleRightNav = this.toggleRightNav.bind(this);
    this.toggleHelperNav = this.toggleHelperNav.bind(this);
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.toggleMobileAccounts = this.toggleMobileAccounts.bind(this);
    this.handleLeftOutsideClick = this.handleLeftOutsideClick.bind(this);
    this.handleRightOutsideClick = this.handleRightOutsideClick.bind(this);
    this.handleHelperOutsideClick = this.handleHelperOutsideClick.bind(this);
    this.handleMobileOutsideClick = this.handleMobileOutsideClick.bind(this);
    this.handleMobileAccountsOutsideClick = this.handleMobileAccountsOutsideClick.bind(
      this
    );
    this.clickMobileNavLink = this.clickMobileNavLink.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.verifySession = this.verifySession.bind(this);
    this.howMuchSessionRemains = this.howMuchSessionRemains.bind(this);
    this.testScheduler = this.testScheduler.bind(this);

    this.state = {
      leftNav: false,
      rightNav: false,
      helperNav: false,
      mobileNav: false,
      mobileAccounts: false,
      subdomain: '',
      scrollHeight: '',
    };
  }

  componentDidMount() {
    if (this.props.currentUserSettings.donately_team_member) {
      setDashboardView('org');
    }
    const top = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({ scrollHeight: top });
    this.updateScroll();
    window.addEventListener('scroll', this.updateScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isDonor !== prevProps.isDonor ||
      this.props.url !== prevProps.url
    ) {
      const nav = document.getElementById('change-topnav-color');
      if (this.props.isDonor === 'true') {
        nav.classList.add('donor-nav');
        nav.classList.remove('account-nav');
      } else {
        nav.classList.add('account-nav');
        nav.classList.remove('donor-nav');
      }
    }
  }

  updateScroll() {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const nav = document.getElementById('change-topnav-color');
    this.setState({ scrollHeight: top });
    if (nav && top >= '50') {
      nav.classList.add('scrolled-nav');
      nav.classList.remove('donor-nav');
      nav.classList.remove('account-nav');
    } else if (nav && top <= '50' && this.props.isDonor === 'false') {
      nav.classList.add('account-nav');
      nav.classList.remove('donor-nav');
      nav.classList.remove('scrolled-nav');
    } else if (nav && top <= '50' && this.props.isDonor === 'true') {
      nav.classList.add('donor-nav');
      nav.classList.remove('account-nav');
      nav.classList.remove('scrolled-nav');
    }
  }

  toggleLeftNav() {
    if (!this.state.leftNav) {
      document.addEventListener('click', this.handleLeftOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleLeftOutsideClick, false);
    }
    this.setState({ leftNav: !this.state.leftNav });
  }

  handleLeftOutsideClick(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      if (e.target.id === 'account-input') {
        return;
      }
      this.toggleLeftNav();
    }
  }

  toggleRightNav() {
    if (!this.state.rightNav) {
      document.addEventListener('click', this.handleRightOutsideClick, false);
    } else {
      document.removeEventListener(
        'click',
        this.handleRightOutsideClick,
        false
      );
    }
    this.setState({ rightNav: !this.state.rightNav });
  }

  handleRightOutsideClick(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleRightNav();
    }
  }

  toggleHelperNav() {
    if (!this.state.helperNav) {
      document.addEventListener('click', this.handleHelperOutsideClick, false);
    } else {
      document.removeEventListener(
        'click',
        this.handleHelperOutsideClick,
        false
      );
    }
    this.setState({ helperNav: !this.state.helperNav });
  }

  handleHelperOutsideClick(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleHelperNav();
    }
  }

  toggleMobileNav() {
    if (!this.state.mobileNav) {
      document.addEventListener('click', this.handleMobileOutsideClick, false);
    } else {
      document.removeEventListener(
        'click',
        this.handleMobileOutsideClick,
        false
      );
    }
    this.setState({ mobileNav: !this.state.mobileNav });
  }

  clickMobileNavLink() {
    document.removeEventListener('click', this.handleMobileOutsideClick, false);
    this.setState({ mobileNav: false });
  }

  handleMobileOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.toggleMobileNav();
  }

  toggleMobileAccounts() {
    if (!this.state.mobileAccounts) {
      document.addEventListener(
        'click',
        this.handleMobileAccountsOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.handleMobileAccountsOutsideClick,
        false
      );
    }
    this.setState({ mobileAccounts: !this.state.mobileAccounts });
  }

  handleMobileAccountsOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.toggleMobileAccounts();
  }

  logout() {
    this.props.auth.logout('TopNav');
  }

  verifySession() {
    this.props.auth.verifyAuthSession();
  }

  howMuchSessionRemains() {
    this.props.auth.secondsToAuthExpiration('Nav Button');
  }

  testScheduler() {
    console.log('testScheduler every 5 seconds');
    scheduleTask(
      () => {
        console.log(' --> it worked!');
      },
      5000,
      10
    );
  }

  toggleDashView(view) {
    setDashboardView(view);
  }

  render() {
    const {
      selected,
      selectedAccount,
      currentUserSettings,
      accounts,
      isDonor,
    } = this.props;

    const isRestrictedForFreePlans = isRestrictedIfMatchesSubscription(
      currentUserSettings,
      selectedAccount,
      [PRICING_PLANS.Free, PRICING_PLANS['Discontinued-Free']]
    );

    return (
      <div
        id="change-topnav-color"
        className={`sm:h-20 h-16 w-full z-20 flex items-center fixed ${
          isDonor === 'true' ? 'donor-nav' : 'account-background'
        }`}
      >
        {/* top-nav normal */}
        <svg
          className="h-4 fill-current mr-2 my-auto pl-3 hidden lg:inline-block w-8"
          viewBox="0 0 24 18.7"
        >
          <path
            d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
            c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
            c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
          />
        </svg>
        {isDonor === 'true' && (
          <div className="mt-6 lg:inline-block my-auto hidden">
            Donor Dashboard
          </div>
        )}
        {isDonor === 'false' && selectedAccount && (
          <div className="navbar my-auto hidden lg:inline-block">
            <div
              className="dropdown my-auto"
              id="account-dropdown"
              ref={node => {
                this.node = node;
              }}
            >
              <button
                id="dropbtn"
                className={
                  this.state.scrollHeight >= '112'
                    ? 'text-grey-darker '
                    : 'text-black pr-8 dropbtn font-normal'
                }
                onClick={() => this.toggleLeftNav()}
              >
                {selectedAccount.hasOwnProperty('title') &&
                selectedAccount.livemode === false
                  ? `${selectedAccount.title} (Test Mode)`
                  : selectedAccount.title}
                {accounts.length > 1 && (
                  <FontAwesomeIcon
                    icon={['far', 'angle-down']}
                    className={`${
                      this.state.scrollHeight >= '112'
                        ? 'text-grey-darker '
                        : 'text-black '
                    }ml-2`}
                  />
                )}
              </button>
              {this.state.leftNav && (
                <div className="dropdown-content m-h-64 z-1000 overflow-y-auto overflow-x-hidden rounded">
                  <ul className="w-full list-reset py-12">
                    {currentUserSettings.donately_team_member && (
                      <li className="px-12">
                        <form>
                          <input
                            className="border border-grey-light rounded py-1 mx-2  w-2/3"
                            onChange={e =>
                              this.setState({ subdomain: e.target.value })
                            }
                            id="account-input"
                          />
                          <button
                            className="dntly-btn-secondary-search mr-2"
                            onClick={() =>
                              this.props.switchAccount(this.state.subdomain)
                            }
                          >
                            Switch
                          </button>
                        </form>
                      </li>
                    )}
                    {accounts.map((account, i) => (
                      <li
                        className="hover:bg-grey-lighter px-12 p-3 select-account flex nowrap text-grey-darker hover:text-teal items-center cursor-pointer bg-white"
                        key={i}
                        onClick={() => selected(account)}
                        value={account.unique_identifier}
                      >
                        <div
                          id="image-border"
                          className="border-pale-blue flex-none  bg-contain rounded-full h-12 w-12 flex items-center justify-center"
                        >
                          {account.images.logo.original ? (
                            <img
                              className="w-8"
                              src={account.images.logo.original}
                              alt=""
                            />
                          ) : (
                            <svg
                              className="h-4 fill-current mr-2 my-auto pl-3 inline-block w-8"
                              viewBox="0 0 24 18.7"
                            >
                              <path
                                d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
                            c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
                            c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
                              />
                            </svg>
                          )}
                        </div>
                        <div className="ml-3 flex-1">
                          {account.livemode === false
                            ? `${account.title} (test mode)`
                            : account.title}
                        </div>
                      </li>
                    ))}
                    <li
                      className="px-12 pt-6 select-account flex nowrap text-green-light hover:text-teal items-center cursor-pointer  "
                      onClick={() =>
                        history.push({
                          pathname: '/create_account',
                          state: { newAccount: true },
                        })
                      }
                    >
                      <div className="h-12 w-12 flex items-center justify-center">
                        <FontAwesomeIcon
                          className=""
                          icon={['far', 'plus']}
                          size="sm"
                        />
                      </div>
                      <div className="ml-1 flex-1">Create another account?</div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="navbar ml-auto my-auto hidden lg:flex">
          <div
            className="dropdown my-auto"
            ref={node => {
              this.node = node;
            }}
          >
            {selectedAccount && (
              <button
                className="pr-4 pt-2 dropbtn inline-flex align-top"
                id="info-dropdown"
                onClick={() => this.toggleHelperNav()}
              >
                <FontAwesomeIcon icon={['fal', 'question-circle']} size="lg" />
              </button>
            )}
            {isDonor === 'false' &&
              history.location.pathname !== '/onboarding' && (
                <Link
                  className="pr-4 pt-2 dropbtn inline-flex align-top"
                  to="/referrals"
                >
                  <FontAwesomeIcon icon={['fal', 'gift']} size="lg" />
                </Link>
              )}
            <div className="pr-4 inline-flex align-bottom">
              {getUserAvatar() && (
                <img
                  className="h-6 w-6 rounded-full"
                  src={getUserAvatar()}
                  alt=""
                />
              )}
            </div>
            {this.state.helperNav && (
              <div
                className="dropdown-content-right-helper"
                ref={node => {
                  this.node = node;
                }}
              >
                <ul className="list-reset">
                  <li>
                    <a
                      className="nav-link"
                      href="https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Submit a Bug
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      href="https://support.donately.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Help Center
                    </a>
                  </li>
                  <li>
                    {localStorage.getItem('apiUpdate') === 'true' &&
                      selectedAccount.billing.subscription_plan !== 'Free' && (
                        <a
                          className="nav-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://donately.com/contact"
                        >
                          Contact Support
                        </a>
                      )}
                    {localStorage.getItem('apiUpdate') === 'true' &&
                      selectedAccount.billing.subscription_plan === 'Free' && (
                        <a
                          className="nav-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td"
                        >
                          Contact Support
                        </a>
                      )}
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      href="https://docs-api-v20190315.donately.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      API Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      href="https://donately.com/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                  </li>
                  {currentUserSettings.donately_team_member && (
                    <li className="border-t border-grey-light pt-4">
                      <ul className="p-0">
                        <li>
                          <a
                            className="nav-link"
                            onClick={() =>
                              this.toggleDashView(
                                localStorage.getItem('dashboardView') === 'team'
                                  ? 'org'
                                  : 'team'
                              )
                            }
                          >
                            Turn Team View{' '}
                            <b>
                              {localStorage.getItem('dashboardView') === 'team'
                                ? 'Off'
                                : 'On'}
                            </b>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>

          <div
            className="dropdown my-auto"
            ref={node => {
              this.node = node;
            }}
          >
            <button
              className="pr-8 dropbtn temp-border top-nav-name-width"
              id="user-dropdown"
              onClick={() => this.toggleRightNav()}
            >
              {getUserName(currentUserSettings)}
              <FontAwesomeIcon icon={['far', 'angle-down']} className="ml-2" />
            </button>
            {this.state.rightNav && (
              <div
                className="dropdown-content-right overflow-y-auto  m-h-72 rounded py-12 "
                ref={node => {
                  this.node = node;
                }}
              >
                <ul className="list-reset">
                  <li className="px-12">
                    <div className="flex flex-col items-center justify-center text-grey-darker">
                      <div>
                        {!currentUserSettings ? (
                          <img alt="" />
                        ) : (
                          <>
                            {getUserAvatar() && (
                              <img
                                className="h-12 w-12 rounded-full"
                                src={getUserAvatar()}
                                alt=""
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className="py-3 flex flex-col justify-center items-center">
                        <div className="text-xl">
                          {currentUserSettings.first_name &&
                            currentUserSettings.first_name
                              .charAt(0)
                              .toUpperCase() +
                              currentUserSettings.first_name.slice(1)}{' '}
                          {currentUserSettings.last_name &&
                            currentUserSettings.last_name
                              .charAt(0)
                              .toUpperCase() +
                              currentUserSettings.last_name.slice(1)}
                        </div>
                        <div className="text-grey-dark text-sm">
                          {currentUserSettings.email}
                        </div>
                      </div>
                    </div>
                  </li>
                  {selectedAccount && (
                    <hr className="border-pale-blue w-full p-0" />
                  )}

                  <li className="px-12 py-4">
                    {isDonor === 'false' && (
                      <>
                        <ul className="p-0">
                          <li>
                            <Link
                              to="/my_profile/dashboard"
                              className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer no-underline"
                              onClick={() => this.props.goToProfile()}
                            >
                              <div className=" h-8 w-8 flex items-center justify-center ">
                                <FontAwesomeIcon
                                  className=""
                                  icon={['far', 'user']}
                                  size="xs"
                                />
                              </div>
                              <div className="text-xs ml-1 flex-1 hover:text-grey-darker">
                                View your donor profile
                              </div>
                            </Link>
                          </li>
                          <li
                            className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer "
                            onClick={() =>
                              history.push({
                                pathname: '/create_account',
                                state: { newAccount: true },
                              })
                            }
                          >
                            <div className=" h-8 w-8 flex items-center justify-center ">
                              <FontAwesomeIcon
                                className=""
                                icon={['far', 'plus']}
                                size="xs"
                              />
                            </div>
                            <div className="text-xs ml-1 flex-1 hover:text-grey-darker">
                              Create another account?
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                    {isDonor === 'true' && selectedAccount && (
                      <li>
                        <Link
                          to="/dashboard"
                          className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer no-underline"
                          onClick={() => this.props.goToAccountDashboard()}
                        >
                          <div className=" h-8 w-8 flex items-center justify-center ">
                            <FontAwesomeIcon
                              className=""
                              icon={['far', 'user']}
                              size="xs"
                            />
                          </div>
                          <div className="text-xs ml-1 flex-1 hover:grey-darker">
                            View your account dashboard
                          </div>
                        </Link>
                      </li>
                    )}
                  </li>
                  <hr className="border-pale-blue w-full p-0" />
                  <li className="select-account flex nowrap text-teal items-center justify-center px-12 pt-6 cursor-pointer">
                    <a
                      className="hover:text-grey-darker mr-3"
                      onClick={this.logout.bind(this)}
                    >
                      Log Out
                    </a>
                  </li>
                  <li className="text-xs px-12 pt-6 flex-1 flex flex-row items-center justify-center space-between">
                    <a
                      className="hover:text-grey-darker mr-3 no-underline text-teal"
                      href="https://donately.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Privacy Policy
                    </a>

                    <div className="text-grey-dark">•</div>
                    <a
                      className="hover:text-grey-darker ml-3 no-underline text-teal"
                      href="https://donately.com/terms-of-use/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Terms of use
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* top-nav mobile */}

        <div className="navbar my-auto block lg:hidden">
          <div
            className="dropdown"
            ref={node => {
              this.node = node;
            }}
          >
            <button
              id="hamburger-nav"
              className="dropbtn"
              onClick={() => this.toggleMobileNav()}
            >
              <svg
                className="h-6 fill-current mr-2 my-auto ml-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  className="heroicon-ui"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              </svg>
            </button>

            {this.state.mobileNav && (
              <div className="mobile-nav-list pin-l pin-r pin-b z-1000 bg-white fixed overflow-y-auto">
                <FontAwesomeIcon
                  icon={['far', 'times']}
                  className="float-right text-grey-darker mr-4 mt-2"
                  onClick={() => this.toggleMobileNav()}
                />

                {isDonor === 'false' && (
                  <ul className="w-full mobile-nav-ul m-auto list-reset pt-8">
                    <li className="dropdown-mobile-li border-b border-t border-grey-light mb-4 bg-white">
                      <div
                        className="dropdown-mobile"
                        ref={node => {
                          this.node = node;
                        }}
                        onClick={() => this.toggleMobileAccounts()}
                      >
                        <button className="dropbtn pr-8 important-text-dark">
                          {selectedAccount &&
                          selectedAccount.hasOwnProperty('title')
                            ? selectedAccount.title
                            : ''}
                          {accounts.length > 1 && (
                            <FontAwesomeIcon
                              icon={['far', 'angle-down']}
                              className="ml-2"
                            />
                          )}
                        </button>
                        {this.state.mobileAccounts && (
                          <div className="dropdown-content overflow-y-auto overflow-x-hidden h-48 w-1/2">
                            <ul className="w-full p-0">
                              {accounts.map((account, i) => (
                                <li
                                  className="p-3 select-account flex nowrap text-grey-darker hover:text-teal items-center cursor-pointer bg-white hover:bg-grey-lighter"
                                  key={i}
                                  onClick={() => selected(account)}
                                  value={account.unique_identifier}
                                >
                                  <div className="border-pale-blue flex-none bg-white bg-contain rounded-full h-12 w-12 flex items-center justify-center">
                                    {account.images.logo.original ? (
                                      <img
                                        className="w-8"
                                        src={account.images.logo.original}
                                        alt=""
                                      />
                                    ) : (
                                      <svg
                                        className="h-4 fill-current mr-2 my-auto pl-3 inline-block w-8"
                                        viewBox="0 0 24 18.7"
                                      >
                                        <path
                                          d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
                              c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
                              c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <div className="ml-3 flex-1">
                                    {account.livemode === false
                                      ? `${account.title} (test mode)`
                                      : account.title}
                                  </div>
                                </li>
                              ))}
                              <li
                                className="p-3 select-account flex nowrap text-grey-darker items-center cursor-pointer bg-white hover:bg-grey-lighter "
                                onClick={() =>
                                  history.push({
                                    pathname: '/create_account',
                                    state: { newAccount: true },
                                  })
                                }
                              >
                                <div className=" h-12 w-12 flex items-center justify-center text-blue-darker">
                                  <FontAwesomeIcon
                                    className=""
                                    icon={['far', 'plus']}
                                    size="sm"
                                  />
                                </div>
                                <div className="ml-1 flex-1 text-green-light">
                                  Create another account?
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/dashboard"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        CRM
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/donors"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Donors
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/donations"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Donations
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/recurring_plans"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Recurring Plans
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        FUNDRAISING
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/campaigns"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Campaigns
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fundraisers"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Fundraisers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/forms"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Forms
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/text-engagement"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Text Engagement
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        SETTINGS
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/settings/account_settings"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Account Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/integrations/zapier_integrations"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        Integrations
                      </Link>
                    </li>
                    {isRestrictedForFreePlans && (
                      <li>
                        <Link
                          to="/settings/billing"
                          onClick={() => this.clickMobileNavLink()}
                        >
                          Upgrade
                        </Link>
                      </li>
                    )}
                    <li className="border-t border-grey-light mt-4">
                      {selectedAccount.hasOwnProperty('billing') &&
                        selectedAccount.billing.subscription_plan !==
                          'Free' && (
                          <a
                            className="cursor-pointer my-4"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://donately.com/contact"
                          >
                            Contact Support
                          </a>
                        )}
                      {selectedAccount.hasOwnProperty('billing') &&
                        selectedAccount.billing.subscription_plan ===
                          'Free' && (
                          <a
                            className="cursor-pointer my-4"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td"
                          >
                            Contact Support
                          </a>
                        )}
                    </li>
                    <li className="border-b border-t border-grey-light py-4">
                      <Link
                        to="/my_profile/dashboard"
                        className="my-4"
                        onClick={() => this.props.goToProfile()}
                      >
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <a
                        className="cursor-pointer my-4"
                        onClick={this.logout.bind(this)}
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                )}

                {isDonor === 'true' && (
                  <ul className="w-full mobile-nav-ul m-auto bg-white list-reset pt-8">
                    <li>
                      <Link
                        to="/my_profile/dashboard"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Dashboard
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        TRANSACTIONS
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/my_profile/donations"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Donations
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/my_profile/recurring_plans"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Recurring Plans
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        FUNDRAISING
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/my_profile/fundraisers"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Fundraisers
                      </Link>
                    </li>
                    <li>
                      <div className="block text-grey-dark text-xs pt-2">
                        SETTINGS
                      </div>
                    </li>
                    <li>
                      <Link
                        to="/my_profile/settings"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/my_profile/tax_report"
                        onClick={() => this.clickMobileNavLink()}
                      >
                        My Tax Report
                      </Link>
                    </li>
                    <li className="border-t border-grey-light mt-4">
                      <a
                        className="cursor-pointer my-4"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://donately.com/contact"
                      >
                        Contact Support
                      </a>
                    </li>
                    <li className="border-b border-t border-grey-light py-4">
                      <Link
                        to="/dashboard"
                        onClick={() => this.props.goToAccountDashboard()}
                      >
                        Account Dashboard
                      </Link>
                    </li>
                    <li className="">
                      <a
                        className="cursor-pointer"
                        onClick={this.logout.bind(this)}
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
