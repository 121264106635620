import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect, Provider, useDispatch, useSelector } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';

import { Auth0Provider } from '@auth0/auth0-react';
import App from './app';
import { AuthenticatedRoute, UnAuthenticatedRoute } from './AppContainers';
import configureStore from './app.store';
import history from '../history';

import CreateNewAccountForm from './authenticated/user-accounts/user-accounts-new.component';

import Auth from '../auth/Auth';
import { fetchAllFundraisersRecursively } from './authenticated/fundraisers/fundraisers.actions';
import { consoleLog } from './library/functions';

const HubspotAppInstallCallback = React.lazy(() =>
  import(
    /* webpackChunkName: "onboarding" */ './authenticated/integrations/hubspot/app-install-callback'
  )
);
const OnboardingSteps = React.lazy(() =>
  import(/* webpackChunkName: "onboarding" */ './authenticated/onboarding')
);
const DonorsList = React.lazy(() =>
  import(/* webpackChunkName: "donors" */ './authenticated/donors')
);
const DonationsList = React.lazy(() =>
  import(/* webpackChunkName: "donations" */ './authenticated/donations')
);
const CampaignsList = React.lazy(() =>
  import(/* webpackChunkName: "campaigns" */ './authenticated/campaigns')
);
const FundraisersList = React.lazy(() =>
  import(/* webpackChunkName: "fundraiser" */ './authenticated/fundraisers')
);
const FormNamespaceContainer = React.lazy(() =>
  import('./authenticated/forms')
);
const SubscriptionsList = React.lazy(() =>
  import(
    /* webpackChunkName: "subscriptions" */ './authenticated/subscriptions'
  )
);
const AccountSettingsList = React.lazy(() =>
  import(
    /* webpackChunkName: "account-settings" */ './authenticated/account-settings'
  )
);
const IntegrationsList = React.lazy(() =>
  import(/* webpackChunkName: "integrations" */ './authenticated/integrations')
);
const StripeRedirect = React.lazy(() =>
  import(
    /* webpackChunkName: "stripe-redirect" */ './authenticated/account-settings/containers/stripe-redirect.container'
  )
);
const PaypalRedirect = React.lazy(() =>
  import(
    /* webpackChunkName: "paypal-redirect" */ './authenticated/account-settings/containers/paypal-redirect.container'
  )
);
const Referrals = React.lazy(() =>
  import(/* webpackChunkName: "referrals" */ './authenticated/referrals')
);
const TextEngagementContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "text-engagement" */ './authenticated/text-engagement/text-engagement.container'
  )
);
const Partners = React.lazy(() =>
  import(/* webpackChunkName: "partners" */ './authenticated/partners')
);
const Tasks = React.lazy(() =>
  import(/* webpackChunkName: "tools" */ './authenticated/tasks')
);
const MyProfile = React.lazy(() =>
  import(/* webpackChunkName: "profile" */ './authenticated/my-profile')
);

const Callback = React.lazy(() =>
  import(/* webpackChunkName: "callback" */ './unauthenticated/callback')
);
const Logout = React.lazy(() =>
  import(/* webpackChunkName: "logout" */ './unauthenticated/logout')
);
const UniversalLogin = React.lazy(() =>
  import(
    /* webpackChunkName: "universal_login" */ './unauthenticated/universal/universal_login'
  )
);
const UniversalSignup = React.lazy(() =>
  import(
    /* webpackChunkName: "universal_signup" */ './unauthenticated/universal/universal_signup'
  )
);
const ActivateDonorProfile = React.lazy(() =>
  import(
    /* webpackChunkName: "activate-donor-profile" */ './unauthenticated/activate-donor-profile.component'
  )
);
const SetCookie = React.lazy(() =>
  import(
    /* webpackChunkName: "set-cookie-endpoint" */ './unauthenticated/set-cookie-endpoint'
  )
);

const NoMatchPage = React.lazy(() =>
  import(
    /* webpackChunkName: "no-match" */ './library/components/no-match-page.component'
  )
);
const Dashboard = React.lazy(() =>
  import('./authenticated/dashboard/dashboard.container')
);

const oAuthUrlsOtherThanAuth0 = [
  'callback/stripe/oauth_authorize',
  'callback/paypal/paypal_authorize',
];

const shouldSkipOauthRedirect = () =>
  oAuthUrlsOtherThanAuth0.some(url => window.location.pathname.includes(url));

const auth = new Auth();
export const store = configureStore();

const onRedirectCallback = appState => {
  if (window && window.DonatelyMarketing && appState?.referrer) {
    window.DonatelyMarketing.marketing_storage.trackParams({
      referrer: appState?.referrer,
    });
  }
  consoleLog('onRedirectCallback', { appState });
  if (appState?.returnTo) {
    if (appState?.returnTo === '/') {
      history.replace({ pathname: '/callback' });
    } else {
      history.replace(appState?.returnTo);
    }
  } else {
    history.replace(window.location.pathname);
  }
};

export const Routes = connect()(() => {
  const { selectedAccount, storeUser } = useSelector(
    state => state.accountReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedAccount && storeUser && storeUser.Authorigin) {
      dispatch(fetchAllFundraisersRecursively(selectedAccount.id, storeUser));
    }
  }, [selectedAccount, storeUser]);

  return (
    <App>
      <Switch>
        <AuthenticatedRoute
          path="/"
          exact
          auth={auth}
          store={store}
          renderSideNav
          component={Dashboard}
        />
        <AuthenticatedRoute
          path="/dashboard"
          auth={auth}
          store={store}
          renderSideNav
          component={Dashboard}
        />
        <AuthenticatedRoute
          path="/onboarding"
          auth={auth}
          store={store}
          renderSideNav={false}
          component={OnboardingSteps}
        />
        <AuthenticatedRoute
          path="/donations"
          auth={auth}
          store={store}
          renderSideNav
          component={DonationsList}
        />
        <AuthenticatedRoute
          path="/donors"
          auth={auth}
          store={store}
          renderSideNav
          component={DonorsList}
        />
        <AuthenticatedRoute
          path="/recurring_plans"
          auth={auth}
          store={store}
          renderSideNav
          component={SubscriptionsList}
        />
        <AuthenticatedRoute
          path="/campaigns"
          auth={auth}
          store={store}
          renderSideNav
          component={CampaignsList}
        />
        <AuthenticatedRoute
          path="/fundraisers"
          auth={auth}
          store={store}
          renderSideNav
          component={FundraisersList}
        />
        <AuthenticatedRoute
          path="/forms"
          auth={auth}
          store={store}
          renderSideNav
          component={FormNamespaceContainer}
        />
        <AuthenticatedRoute
          path="/text-engagement"
          auth={auth}
          store={store}
          renderSideNav
          component={TextEngagementContainer}
        />
        <AuthenticatedRoute
          path="/settings"
          auth={auth}
          store={store}
          renderSideNav
          component={AccountSettingsList}
        />
        <AuthenticatedRoute
          path="/partners"
          auth={auth}
          store={store}
          renderSideNav
          component={Partners}
        />
        <AuthenticatedRoute
          path="/tasks"
          auth={auth}
          store={store}
          renderSideNav
          component={Tasks}
        />
        <AuthenticatedRoute
          path="/referrals"
          auth={auth}
          store={store}
          renderSideNav
          component={Referrals}
        />
        <AuthenticatedRoute
          path="/integrations"
          auth={auth}
          store={store}
          renderSideNav
          component={IntegrationsList}
        />
        <AuthenticatedRoute
          path="/my_profile"
          auth={auth}
          store={store}
          renderSideNav
          component={MyProfile}
        />
        <AuthenticatedRoute
          exact
          path="/callback/stripe/oauth_authorize"
          auth={auth}
          store={store}
          renderSideNav
          component={StripeRedirect}
        />
        <AuthenticatedRoute
          exact
          path="/callback/paypal/paypal_authorize"
          auth={auth}
          store={store}
          renderSideNav
          component={PaypalRedirect}
        />
        <AuthenticatedRoute
          path="/create_account"
          auth={auth}
          store={store}
          renderSideNav
        >
          <CreateNewAccountForm />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path="/hubspot/app_install_callback"
          auth={auth}
          store={store}
          renderSideNav
          component={HubspotAppInstallCallback}
        />
        {/* Begin UnAuthenticated Routes */}
        <UnAuthenticatedRoute
          path="/login"
          auth={auth}
          component={UniversalLogin}
        />
        <UnAuthenticatedRoute
          path="/signup/referrals/:referralId"
          auth={auth}
          component={UniversalSignup}
        />
        <UnAuthenticatedRoute
          path="/signup"
          auth={auth}
          component={UniversalSignup}
        />
        <UnAuthenticatedRoute
          path="/universal_login"
          auth={auth}
          component={UniversalLogin}
        />
        <UnAuthenticatedRoute
          path="/universal_signup"
          auth={auth}
          component={UniversalSignup}
        />
        <UnAuthenticatedRoute path="/logout" auth={auth} component={Logout} />
        <UnAuthenticatedRoute
          path="/activate_donor_profile"
          auth={auth}
          component={ActivateDonorProfile}
        />
        <UnAuthenticatedRoute
          exact
          path="/callback"
          auth={auth}
          component={Callback}
        />
        <UnAuthenticatedRoute path="/sc" component={SetCookie} />
        {/* End UnAuthenticated Routes */}
        <AuthenticatedRoute
          path="*"
          auth={auth}
          store={store}
          renderSideNav
          component={NoMatchPage}
        />
      </Switch>
    </App>
  );
});

export const makeMainRoutes = () => (
  <Auth0Provider
    domain={process.env.AUTH0_DOMAIN}
    clientId={process.env.AUTH0_CLIENT_ID}
    redirectUri={process.env.AUTH0_REDIRECT_URL}
    onRedirectCallback={onRedirectCallback}
    skipRedirectCallback={shouldSkipOauthRedirect}
    audience={process.env.AUTH0_AUDIENCE}
    useRefreshTokens
  >
    <Provider store={store}>
      <Router history={history}>
        <HttpsRedirect>
          <Routes />
        </HttpsRedirect>
      </Router>
    </Provider>
  </Auth0Provider>
);
