import {
  HIDE_MODAL,
  HIDE_UPGRADE_MODAL,
  SHOW_MESSAGE,
  SHOW_UPGRADE_MODAL,
} from './modal.actions';

export default function(
  state = {
    open: false,
    text: '',
    type: 'success',
    onAgree: null,
    export: false,
    noCancel: true,
    upgradeModal: {
      open: false,
      plan: null,
      isYearly: false,
      onSuccessCallback: null,
    },
  },
  action
) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        open: true,
        text: action.text,
        type: action.type,
        onAgree: action.onAgree,
        export: action.export,
        noCancel: action.noCancel,
      };
    case HIDE_MODAL:
      return {
        ...state,
        open: false,
        text: '',
        onAgree: null,
        export: false,
        noCancel: true,
      };
    case SHOW_UPGRADE_MODAL:
      return {
        ...state,
        upgradeModal: {
          open: true,
          plan: action.plan,
          isYearly: action.isYearly,
          onUpgradeSuccess: action.onUpgradeSuccess,
        },
      };
    case HIDE_UPGRADE_MODAL:
      return {
        ...state,
        upgradeModal: {
          open: false,
          plan: null,
          onUpgradeSuccess: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
