import React from 'react';

const LoadingAccounts = () => (
  <>
    <div className="main-loading absolute pin-l pin-r pin-b pin-t bg-grey-lighter flex items-center z-1000">
      <div className="m-auto loading-heart flex-col text-center">
        <svg
          className="h-24 fill-current inline-block m-auto"
          viewBox="0 0 24 18.7"
        >
          <path
            d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
                c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
                c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
          />
        </svg>
        <div className="text-grey-darker text-2xl mt-8">
          Loading your information
        </div>
        <div className="loader">Loading...</div>
      </div>
    </div>
    <div className="absolute bg-grey-lighter w-full h-64 z-10 bottom-loading-div" />
  </>
);

export default LoadingAccounts;
