import React from 'react';

const IPadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="fill-current h-6 w-6 "
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      className="heroicon-ui"
      d="M6 2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm0 2v16h12V4H6zm6 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>
);

export default IPadSvg;
