import { combineReducers } from 'redux';
import {
  SET_USER,
  SELECTED_ACCOUNT,
  INVALIDATE_ACCOUNT,
  REQUEST_ACCOUNTS,
  RECEIVE_ACCOUNTS,
  IS_DONOR,
  INVALIDATE_PARTNER_ACCOUNTS,
  RECEIVE_PARTNER_ACCOUNTS,
  REQUEST_PARTNER_ACCOUNTS,
} from './user-accounts.actions';
import { HEADERS } from '../../library/constants';
import { UPDATE_SCRIPT_TAGS_SUCCESS } from '../account-settings/account-settings.actions';

export function storeUser(
  state = Object.assign(HEADERS, {
    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
  }),
  action
) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
}

function selectedAccount(
  state = JSON.parse(localStorage.getItem('selected_account')),
  action
) {
  switch (action.type) {
    case SELECTED_ACCOUNT:
      return action.account;
    case UPDATE_SCRIPT_TAGS_SUCCESS:
      return {
        ...state,
        script_tags: action.script_tags,
      };
    default:
      return state;
  }
}

function isDonor(state = localStorage.getItem('isDonor'), action) {
  switch (action.type) {
    case IS_DONOR:
      return action.value;
    default:
      return state;
  }
}

function accounts(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_ACCOUNT:
      return { ...state, didInvalidate: true };
    case REQUEST_ACCOUNTS:
      return { ...state, didInvalidate: false, isFetching: true };
    case RECEIVE_ACCOUNTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.accounts,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export function accountsByUser(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_ACCOUNT:
    case RECEIVE_ACCOUNTS:
    case REQUEST_ACCOUNTS:
      return { ...state, [action.user]: accounts(state[action.user], action) };
    default:
      return state;
  }
}

function partnerAccounts(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_PARTNER_ACCOUNTS:
      return { ...state, didInvalidate: true };
    case REQUEST_PARTNER_ACCOUNTS:
      return { ...state, didInvalidate: false, isFetching: true };
    case RECEIVE_PARTNER_ACCOUNTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.accounts,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export function partnerAccountsList(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_PARTNER_ACCOUNTS:
    case REQUEST_PARTNER_ACCOUNTS:
    case RECEIVE_PARTNER_ACCOUNTS:
      return {
        ...state,
        [action.user]: partnerAccounts(state[action.user], action),
      };
    default:
      return state;
  }
}

const accountReducer = combineReducers({
  selectedAccount,
  isDonor,
  accountsByUser,
  storeUser,
  partnerAccountsList,
});

export default accountReducer;
