import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './app.reducers';

const ignore_all = !process.env.REDUX_LOGGING;
const only_actions = ['@@redux-form/REGISTER_FIELD'];
const ignored_actions = [
  'SET_USER',
  'REQUEST_USER_SETTINGS',
  'REQUEST_ACCOUNTS',
  'REQUEST_CAMPAIGN',
  'REQUEST_FORMS',
  'IS_DONOR',
  'RECEIVE_USER_SETTINGS',
  'SET_FORM_QUERY',
  'RECEIVE_FORMS',
  'RECEIVE_ACCOUNTS',
  'SELECTED_ACCOUNT',
  'SELECTED_CAMPAIGN',
  'REQUEST_FORM',
  'REQUEST_DONATIONS',
  'SELECTED_FORM',
  'RECEIVE_DONATIONS',
];

const loggerMiddleware = createLogger({
  predicate(getState, action) {
    if (ignore_all) {
      return false;
    }
    if (only_actions && only_actions.length > 0) {
      if (only_actions.includes(action.type)) {
        return true;
      }

      return false;
    }
    if (ignored_actions.includes(action.type)) {
      return false;
    }

    return true;
  },
});

export default function configureStore(preloadedState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
  );
  return store;
}
