import React from 'react';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import { rebuildApiHeaders, consoleLog } from '../../library/functions';
import history from '../../../history';

export const REQUEST_USER_SETTINGS = 'REQUEST_USER_SETTINGS';

export function requestUserSettings(headers) {
  return {
    type: REQUEST_USER_SETTINGS,
    headers,
  };
}

export const REQUEST_MY_DONATIONS = 'REQUEST_MY_DONATIONS';

export function requestMyDonations(headers) {
  return {
    type: REQUEST_MY_DONATIONS,
    headers,
  };
}

export const REQUEST_MY_FUNDRAISERS = 'REQUEST_MY_FUNDRAISERS';

export function requestMyFundraisers(headers) {
  return {
    type: REQUEST_MY_FUNDRAISERS,
    headers,
  };
}

export const REQUEST_MY_SUBSCRIPTIONS = 'REQUEST_MY_SUBSCRIPTIONS';

export function requestMySubscriptions(headers) {
  return {
    type: REQUEST_MY_SUBSCRIPTIONS,
    headers,
  };
}

export const RECEIVE_USER_SETTINGS = 'RECEIVE_USER_SETTINGS';

export function receiveUserSettings(json) {
  return {
    type: RECEIVE_USER_SETTINGS,
    userSettings: json.data,
    receivedAt: Date.now(),
  };
}

export const RECEIVE_MY_DONATIONS = 'RECEIVE_MY_DONATIONS';

export function receiveMyDonations(json) {
  return {
    type: RECEIVE_MY_DONATIONS,
    myDonations: json.data,
    summary: json.summary,
    receivedAt: Date.now(),
  };
}

export const RECEIVE_MY_SUBSCRIPTIONS = 'RECEIVE_MY_SUBSCRIPTIONS';

export function receiveMySubscriptions(json) {
  return {
    type: RECEIVE_MY_SUBSCRIPTIONS,
    mySubscriptions: json.data,
    summary: json.summary,
    receivedAt: Date.now(),
  };
}

export const RECEIVE_MY_FUNDRAISERS = 'RECEIVE_MY_FUNDRAISERS';

export function receiveMyFundraisers(json) {
  return {
    type: RECEIVE_MY_FUNDRAISERS,
    myFundraisers: json.data,
    summary: json.summary,
    receivedAt: Date.now(),
  };
}

export const INVALIDATE_USER_SETTING = 'INVALIDATE_USER_SETTING';

export function invalidateUserSetting(setting) {
  return {
    type: INVALIDATE_USER_SETTING,
    setting,
  };
}

export const EDIT_USER_SETTINGS = 'EDIT_USER_SETTINGS';

export function editUserSettings(headers) {
  return {
    type: EDIT_USER_SETTINGS,
    headers,
  };
}

export const RECEIVE_TAX_REPORT = 'RECEIVE_TAX_REPORT';

export function receiveTaxReport(json) {
  return {
    type: RECEIVE_TAX_REPORT,
    taxReport: json,
    receivedAt: Date.now(),
  };
}

const attemptUserFetch = function(url, headers, dispatch, attempts) {
  if (!headers.Authorigin) {
    headers.Authorigin = 'universal_login'; // default value
  }
  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  }).then(function(response) {
    // consoleLog('attemptUserFetch response', {
    //   status: response && response.status,
    // });
    if (response.status !== 200) {
      const currentAttempts = attempts + 1;
      if (currentAttempts < 3) {
        // retry three times
        return attemptUserFetch(url, headers, dispatch, currentAttempts);
      }
      history.push('/logout');
    } else {
      return response.json().then(json => {
        dispatch(receiveUserSettings(json));
      });
    }
  });
};

export function fetchUserSettings(headers) {
  const url = Queries.getMeQuery();
  return dispatch => attemptUserFetch(url.href, headers, dispatch, 0);
}

export function fetchMyDonations(headers, offset = '0', query) {
  const url = Queries.getMyDonationsQuery(offset, query);
  return dispatch => {
    dispatch(requestMyDonations(headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('requestMyDonations error');
      } else {
        return response.json().then(json => dispatch(receiveMyDonations(json)));
      }
    });
  };
}

export function fetchMyOrderedDonations(headers, offset = '0', query) {
  const url = Queries.getMyOrderedDonations(offset, query);
  return dispatch => {
    dispatch(requestMyDonations(headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('requestMyDonations error');
      } else {
        return response.json().then(json => dispatch(receiveMyDonations(json)));
      }
    });
  };
}

export function fetchMySubscriptions(headers, offset = '0') {
  const url = Queries.getMySubscriptionsQuery(offset);
  return dispatch => {
    dispatch(requestMySubscriptions(headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchMySubscriptions error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveMySubscriptions(json)));
      }
    });
  };
}

export function fetchMyOrderedSubscriptions(headers, offset = '0', query) {
  const url = Queries.getMyOrderedSubscriptions(offset, query);
  return dispatch => {
    dispatch(requestMySubscriptions(headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchMySubscriptions error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveMySubscriptions(json)));
      }
    });
  };
}

export function fetchStripeKey(headers, accountId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V1_URL}/accounts/${accountId}/get_stripe_publishable_key.json`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200 && response.status !== 304) {
        console.log('fetchStripeKey error');
      } else {
        return response.json();
      }
    });
}

export function fetchMyFundraisers(headers, offset = '0') {
  const url = Queries.getMyFundraisersQuery(offset);
  return dispatch => {
    dispatch(requestMyFundraisers(headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchMyFundraisers error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveMyFundraisers(json)));
      }
    });
  };
}

export function updateUserSettings(headers, myId, updateValues) {
  return dispatch => {
    dispatch(editUserSettings(headers));
    return fetch(`${process.env.DONATELY_API_V2_URL}/people/${myId}.json`, {
      method: 'POST',
      body: JSON.stringify(updateValues),
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(dispatch(fetchUserSettings(headers)));
    });
  };
}

export function resendEmailReceipt(headers, donationId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/donations/${donationId}/send_receipt.json`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('resendEmailReceipt error');
      } else {
        return response.json();
      }
    });
}

export function fetchTaxReport(headers, startTimestamp, endTimestamp) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/people/tax_receipt.json?tax_year_start=${startTimestamp}&tax_year_end=${endTimestamp}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchTaxReport error');
      } else {
        return response.json().then(json => dispatch(receiveTaxReport(json)));
      }
    });
}
