import { receiveFail } from '../donations/donations.actions';
import { rebuildApiHeaders } from '../../library/functions';

export const REQUEST_TEXT2GIVE = 'REQUEST_TEXT2GIVE';

export function requestText2give(accountIdentifier, headers) {
  return {
    type: REQUEST_TEXT2GIVE,
    accountIdentifier,
    headers,
  };
}

export const RECEIVE_TEXT2GIVE = 'RECEIVE_TEXT2GIVE';

export function receiveText2give(accountIdentifier, json) {
  return {
    type: RECEIVE_TEXT2GIVE,
    accountIdentifier,
    text2give: json.data,
  };
}

export function getText2vgive(accountIdentifier, headers) {
  return dispatch => {
    dispatch(requestText2give(accountIdentifier, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/text_to_gives.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json => dispatch(receiveText2give(accountIdentifier, json)));
    });
  };
}

export function updateTextEngagement(
  accountIdentifier,
  headers,
  id,
  code,
  message
) {
  return dispatch =>
    fetch(`${process.env.DONATELY_API_V2_URL}/text_to_gives/${id}.json`, {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
      body: JSON.stringify({
        account_id: accountIdentifier,
        code,
        message,
      }),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, Date.now())));
      }
      return response.json().then(json => {
        dispatch(
          receiveFail(`Text engagement successfully updated.`, Date.now())
        );
        dispatch(getText2vgive(accountIdentifier, headers));
      });
    });
}

export function createTextEngagement(
  accountIdentifier,
  headers,
  code,
  message
) {
  return dispatch =>
    fetch(`${process.env.DONATELY_API_V2_URL}/text_to_gives.json`, {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
      body: JSON.stringify({
        account_id: accountIdentifier,
        code,
        message,
      }),
    }).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, Date.now())));
      }
      return response.json().then(json => {
        dispatch(
          receiveFail(`Text engagement successfully created.`, Date.now())
        );
        dispatch(getText2vgive(accountIdentifier, headers));
      });
    });
}

export function removeText2give(accountIdentifier, headers, text2giveId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/text_to_gives/${text2giveId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail(`Text engagement successfully deleted.`));
        dispatch(getText2vgive(accountIdentifier, headers));
      });
    });
}
