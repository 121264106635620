import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import '../../../index.css';

const Tooltip = ({
  customClass,
  customDivClass,
  tipType,
  size,
  viewBoxSize,
  text,
  link,
  linkText,
  handleClick,
  target,
  textAfterLink,
  linkColor,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipEl = useRef(null);

  const handleOutsideClick = e => {
    if (tooltipEl.current) {
      if (tooltipEl.current.contains(e.target)) {
        return;
      }
    }
    setIsTooltipOpen(false);
  };

  const tooltipClasses = () => {
    if (tipType === 'alert') {
      return 'cursor-pointer';
    }
    if (linkColor) {
      return `fill-${linkColor} cursor-pointer`;
    }
    return 'fill-current cursor-pointer';
  };

  const tooltipStroke = () => {
    if (tipType === 'alert') {
      return '#e16f63';
    }
    return null;
  };

  const tooltipStrokeWidth = () => {
    if (tipType === 'alert') {
      return '2';
    }
    return null;
  };

  const tooltipFill = () => {
    if (tipType === 'alert') {
      return 'none';
    }
    return null;
  };

  useEffect(() => {
    if (!isTooltipOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTooltipOpen]);

  return (
    <div className={customClass || 'text-grey-dark flex pl-1'} ref={tooltipEl}>
      <svg
        onClick={() => setIsTooltipOpen(true)}
        className={tooltipClasses()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        width={size}
        height={size}
        fill={tooltipFill()}
        stroke={tooltipStroke()}
        strokeWidth={tooltipStrokeWidth()}
      >
        {tipType === 'info' && (
          <path
            className="heroicon-ui"
            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        )}
        {tipType === 'alert' && (
          <path
            className="heroicon-ui"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        )}
      </svg>

      {isTooltipOpen && (
        <div
          className={
            customDivClass ||
            'bg-white rounded p-2 ml-2 w-64 text-grey-darker text-sm p-8 absolute shadow-lg z-5 break-words normal-case'
          }
          onClick={handleClick}
        >
          {text}
          <a
            className="link-dntly-blue ml-1"
            href={link}
            target={target}
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
          {textAfterLink}
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  text: '',
  link: '',
  linkText: '',
  tipType: 'info',
  textAfterLink: '',
  target: '_blank',
  linkColor: '',
  viewBoxSize: 24,
  size: 20,
  customClass: '',
  customDivClass: '',
};

Tooltip.propTypes = {
  text: PropTypes.any,
  link: PropTypes.any,
  linkText: PropTypes.any,
  tipType: PropTypes.string,
  target: PropTypes.string,
  textAfterLink: PropTypes.any,
  linkColor: PropTypes.any,
  handleClick: PropTypes.any,
  size: PropTypes.number,
  viewBoxSize: PropTypes.number,
  customClass: PropTypes.any,
  customDivClass: PropTypes.any,
};

export default Tooltip;
