import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditFundraiser = () => (
  <div className="fa-layers fa-fw block mx-auto mb-2">
    <FontAwesomeIcon icon={['fal', 'browser']} size="lg" />
    <FontAwesomeIcon
      icon={['fal', 'edit']}
      transform="shrink-4"
      style={{ background: '#f1f5f8', transform: 'translate(11px, 7px)' }}
    />
  </div>
);

export default EditFundraiser;
