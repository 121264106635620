import { combineReducers } from 'redux';
import {
  SELECTED_SUBSCRIPTION,
  RECEIVE_SUBSCRIPTIONS,
  REQUEST_SUBSCRIPTIONS,
  RECEIVE_EXPORT,
  SET_SUBSCRIPTION_QUERY,
  RECEIVE_FAILED_SUBSCRIPTIONS,
  REQUEST_FAILED_SUBSCRIPTIONS,
  RECEIVE_EXPIRING_SUBSCRIPTIONS,
  REQUEST_EXPIRING_SUBSCRIPTIONS,
  RECEIVE_EMPTY_SUBSCRIPTIONS,
  CLEAR_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_CACHED_QUERY,
} from './subscriptions.actions';

export function selectedSubscription(state = {}, action) {
  switch (action.type) {
    case SELECTED_SUBSCRIPTION:
      return action.subscription;
    default:
      return state;
  }
}

export function receiveSubscriptionQuery(state = {}, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_QUERY:
      return action.query;
    default:
      return state;
  }
}

export function exportMessage(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPORT:
      return action.message;
    default:
      return state;
  }
}

function subscriptions(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    summary: {},
    orderBy: '',
    pages: {},
    offset: null,
    cachedQuery: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_SUBSCRIPTIONS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_SUBSCRIPTIONS:
      let page = 0;
      if (action.offset > 0) {
        page = action.offset / 20;
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.subscriptions,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
        pages: {
          ...state.pages,
          [page]: {
            items: action.subscriptions,
            invalidateAfter: action.invalidTimestamp,
          },
        },
        offset: action.offset,
      };
    case REQUEST_FAILED_SUBSCRIPTIONS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_FAILED_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.subscriptions,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
      };
    case REQUEST_EXPIRING_SUBSCRIPTIONS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_EXPIRING_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.subscriptions,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_EMPTY_SUBSCRIPTIONS:
      return { ...state, isFetching: false };
    case CLEAR_SUBSCRIPTIONS:
      return { ...state, summary: {}, pages: {}, offset: null };
    case SET_SUBSCRIPTIONS_CACHED_QUERY:
      return { ...state, cachedQuery: action.cachedQuery };
    default:
      return state;
  }
}

export function subscriptionsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTIONS:
    case REQUEST_SUBSCRIPTIONS:
    case RECEIVE_EMPTY_SUBSCRIPTIONS:
    case CLEAR_SUBSCRIPTIONS:
    case SET_SUBSCRIPTIONS_CACHED_QUERY:
      return {
        ...state,
        [action.account_identifier]: subscriptions(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

export function failedRecurringPlans(state = {}, action) {
  switch (action.type) {
    case RECEIVE_FAILED_SUBSCRIPTIONS:
    case REQUEST_FAILED_SUBSCRIPTIONS:
      return {
        ...state,
        [action.account_identifier]: subscriptions(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

export function expiringRecurringPlans(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EXPIRING_SUBSCRIPTIONS:
    case REQUEST_EXPIRING_SUBSCRIPTIONS:
      return {
        ...state,
        [action.account_identifier]: subscriptions(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const subscriptionReducer = combineReducers({
  subscriptionsByAccount,
  failedRecurringPlans,
  expiringRecurringPlans,
  receiveSubscriptionQuery,
  selectedSubscription,
  exportMessage,
});

export default subscriptionReducer;
