import { combineReducers } from 'redux';
import {
  RECEIVE_USER_SETTINGS,
  REQUEST_MY_DONATIONS,
  RECEIVE_MY_DONATIONS,
  RECEIVE_MY_SUBSCRIPTIONS,
  RECEIVE_MY_FUNDRAISERS,
  RECEIVE_TAX_REPORT,
  REQUEST_MY_FUNDRAISERS,
  REQUEST_MY_SUBSCRIPTIONS,
} from './my-profile.actions';

export function userSettings(state = {}, action) {
  switch (action.type) {
    case RECEIVE_USER_SETTINGS:
      return action.userSettings;
    default:
      return state;
  }
}

export function taxReport(state = {}, action) {
  switch (action.type) {
    case RECEIVE_TAX_REPORT:
      return action.taxReport;
    default:
      return state;
  }
}

export function myDonations(
  state = {
    items: [],
    summary: {},
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case RECEIVE_MY_DONATIONS:
      return {
        ...state,
        items: action.myDonations,
        summary: action.summary,
        isFetching: false,
      };
    case REQUEST_MY_DONATIONS:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}

export function mySubscriptions(
  state = {
    items: [],
    summary: {},
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case RECEIVE_MY_SUBSCRIPTIONS:
      return {
        ...state,
        items: action.mySubscriptions,
        summary: action.summary,
        isFetching: false,
      };
    case REQUEST_MY_SUBSCRIPTIONS:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}

export function myFundraisers(
  state = {
    items: [],
    summary: {},
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case RECEIVE_MY_FUNDRAISERS:
      return {
        ...state,
        items: action.myFundraisers,
        summary: action.summary,
        isFetching: false,
      };
    case REQUEST_MY_FUNDRAISERS:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}

const myProfileReducer = combineReducers({
  userSettings,
  myDonations,
  mySubscriptions,
  myFundraisers,
  taxReport,
});

export default myProfileReducer;
