import React from 'react';
import { receiveFail } from '../donations/donations.actions';
import { rebuildApiHeaders } from '../../library/functions';
import { emptyPromise, isEmpty } from '../../library/utils';

export const REQUEST_INSIGHTS = 'REQUEST_INSIGHTS';

export function requestInsights(account_identifier, headers) {
  return {
    type: REQUEST_INSIGHTS,
    account_identifier,
    headers,
  };
}

export const RECEIVE_INSIGHTS = 'RECEIVE_INSIGHTS';

export function receiveInsights(account_identifier, json) {
  return {
    type: RECEIVE_INSIGHTS,
    account_identifier,
    insights: json.data,
    receivedAt: Date.now(),
  };
}

export function fetchInsights(account_identifier, headers) {
  return (dispatch, getState) => {
    dispatch(requestInsights(account_identifier, headers));
    const state = getState().dashboardReducer;

    // Verify if we should fetch or not given the selected account.
    if (!isEmpty(state.insightsByAccount[account_identifier].insights)) {
      dispatch(
        receiveInsights(account_identifier, {
          data: state.insightsByAccount[account_identifier].insights,
        })
      );
      return emptyPromise(state.insightsByAccount[account_identifier].insights);
    }

    // Fetch if data was not obtained for this account.
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/statistics.json?account_id=${account_identifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveInsights(account_identifier, json));
        return json.data;
      });
  };
}

export function updateInsights(account_identifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/statistics/${account_identifier}.json`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.data.message, Date.now())));
      }
    });
}
